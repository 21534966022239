import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  GetCandidatesApplicationsByStageDocument,
  GetCandidateApplicationFiltersDataDocument,
  CountCandidateApplicationDocument,
  GetCandidateApplicationDocument,
  GetRequisitionPipelineDocument,
  useRejectCandidatesMutation,
  useGetCandidatesApplicationsByStageQuery,
  NameEnum,
} from "~/bff/graphql/generated/graphql";
import { notificationErrorHandler } from "~/utils/error";
import {
  Drawer,
  NotificationKind,
  useNotification,
} from "~/scalis-components/core";
import { SuccessButtonProps } from "~/scalis-components/core/drawer/drawer.types";
import { getCandidatesApplications } from "../pipeline.utils";
import { DiscardCandidateForm } from "./discard-candidate-form";
import { discardCandidateFormSchema } from "./discard-candidate-drawer.constants";
import {
  DiscardCandidateDrawerProps,
  DiscardCandidateFormValues,
} from "./discard-candidate-drawer.types";

export const DiscardCandidateDrawer = ({
  requisitionId,
  defaultValues,
  ...props
}: DiscardCandidateDrawerProps) => {
  const { notification } = useNotification();

  const form = useForm<DiscardCandidateFormValues>({
    resolver: zodResolver(discardCandidateFormSchema),
    defaultValues: {
      applicationIds: [],
      ...defaultValues,
    },
  });

  const { data: candidatesData, loading: isLoadingCandidates } =
    useGetCandidatesApplicationsByStageQuery({
      variables: {
        requisitionId: requisitionId,
        milestoneIn: [NameEnum.Sourcing],
      },
      skip: !requisitionId,
    });

  const [rejectCandidate, { loading }] = useRejectCandidatesMutation();

  const candidates = getCandidatesApplications(candidatesData);

  const successButtonProps: SuccessButtonProps = {
    label: "Remove",
    loading: loading,
    onClick: form.handleSubmit(async values => {
      try {
        const { applicationIds } = values;
        await rejectCandidate({
          variables: {
            input: {
              applicationIds: applicationIds.map(id => Number(id)),
              medalistDesignation: "",
              questions: [],
            },
          },
          refetchQueries: [
            GetCandidatesApplicationsByStageDocument,
            GetCandidateApplicationFiltersDataDocument,
            CountCandidateApplicationDocument,
            GetCandidateApplicationDocument,
            GetRequisitionPipelineDocument,
          ],
        });

        props.onOpenChange?.(false);

        notification({
          title: "Success",
          description: `Candidate${
            applicationIds.length > 1 ? "s were" : " was"
          } successfully removed.`,
          kind: NotificationKind.success,
        });
      } catch (error) {
        notificationErrorHandler({ notification });
      }
    }),
  };

  const cancelButtonProps = {
    label: "Cancel",
    disabled: loading,
  };

  return (
    <Drawer
      {...props}
      title="Remove Candidate"
      contentClassName="p-4"
      endAlignedFooterActions={true}
      successButtonProps={successButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <DiscardCandidateForm
        form={form}
        candidates={candidates}
        isLoadingCandidates={isLoadingCandidates}
        isLoading={loading}
      />
    </Drawer>
  );
};
