"use client";

import {
  GetCandidatesApplicationsByStageDocument,
  GetCandidateApplicationFiltersDataDocument,
  CountCandidateApplicationDocument,
  GetCandidateApplicationDocument,
  GetRequisitionPipelineDocument,
  useRejectCandidatesMutation,
} from "~/bff/graphql/generated/graphql";
import { notificationErrorHandler } from "~/utils/error";
import {
  ConfirmationDialog,
  ConfirmationDialogKind,
  NotificationKind,
  useNotification,
} from "~/scalis-components/core";
import { RejectCandidateConfirmationDialogProps } from "./reject-candidate-confirmation-dialog.types";

export const RejectCandidateConfirmationDialog = ({
  applicationIds,
  customMessage,
  templateMessageId,
  reason,
  medalistDesignation,
  details,
  questions,
  ...props
}: RejectCandidateConfirmationDialogProps) => {
  const { notification } = useNotification();

  const [rejectCandidate, { loading }] = useRejectCandidatesMutation();

  const shouldPluralize = applicationIds.length > 1;
  const isBulkRejection = applicationIds.length >= 10;
  const title = isBulkRejection
    ? "Bulk Candidate Rejection"
    : `Reject Candidate${shouldPluralize ? "s" : ""}`;
  const message = isBulkRejection
    ? `Warning! You’re about to reject ${applicationIds.length} candidates. This action cannot be undone.`
    : `Are you sure you want to reject ${shouldPluralize ? "these candidates" : "this candidate"}? This action cannot be undone.`;
  const labelConfirm = isBulkRejection ? "Reject Candidates" : "Reject";

  const handleReject = async () => {
    try {
      await rejectCandidate({
        variables: {
          input: {
            applicationIds: applicationIds.map(id => Number(id)),
            customMessage,
            reason,
            templateMessageId: templateMessageId
              ? Number(templateMessageId)
              : undefined,
            medalistDesignation: medalistDesignation ?? "",
            details,
            questions:
              questions?.map(question => ({
                questionId: question.questionId,
                score: question.score ?? 0,
              })) ?? [],
          },
        },
        refetchQueries: [
          GetCandidatesApplicationsByStageDocument,
          GetCandidateApplicationFiltersDataDocument,
          CountCandidateApplicationDocument,
          GetCandidateApplicationDocument,
          GetRequisitionPipelineDocument,
        ],
      });

      notification({
        title: "Success",
        description: `Candidate${
          shouldPluralize ? "s were" : " was"
        } successfully rejected.`,
        kind: NotificationKind.success,
      });
    } catch (error) {
      notificationErrorHandler({ notification });
    }

    props.onOpenChange?.(false);
  };

  const handleCancel = () => {
    props.onOpenChange?.(false);
  };

  return (
    <ConfirmationDialog
      {...props}
      title={title}
      message={message}
      onCancel={handleCancel}
      onConfirm={handleReject}
      labelConfirm={labelConfirm}
      kind={ConfirmationDialogKind.danger}
      onEscapeKeyDown={e => e.preventDefault()}
      onPointerDown={e => e.preventDefault()}
      onInteractOutside={e => e.preventDefault()}
      loading={loading}
    />
  );
};
