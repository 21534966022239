import { DrawerProps } from "~/scalis-components/core/drawer/drawer.types";

export interface ScheduleOptionDrawerProps
  extends Omit<
    DrawerProps,
    | "title"
    | "endAlignedFooterActions"
    | "successButtonProps"
    | "cancelButtonProps"
  > {
  onSubmit?: (scheduleOption: ScheduleOption) => void;
}

export enum ScheduleOption {
  LINK = "link",
  CALENDAR = "calendar",
}
