import { PropsWithChildren } from "react";
import { cn } from "~/utils/cn";
import { Avatar } from "../avatar";
import { Body, BodySize, Caption, CaptionColor } from "../typography";
import { UserInfoProps } from "./user-info.types";

export const UserInfo = ({
  src,
  fullName,
  email,
  onClick,
  children,
}: PropsWithChildren<UserInfoProps>) => {
  return (
    <div
      onClick={onClick}
      className={cn("flex items-center gap-2", onClick && "cursor-pointer")}
    >
      <Avatar src={src} fullName={fullName} />
      <div className="flex flex-col">
        <Body size={BodySize.small}>{fullName}</Body>
        {email && <Caption color={CaptionColor.secondary}>{email}</Caption>}
      </div>
      {children && <div className="ml-auto">{children}</div>}
    </div>
  );
};
