import { useMemo, useState } from "react";
import Image from "next/image";
import { ScheduleInterviewDrawerProps } from "./schedule-interview-drawer.types";
import { milestoneNameMap } from "~/utils/enum-mappers";
import { normalizeMilestones } from "../../../(applications)/pipeline/components/kanban-view/kanban-view.utils";

import {
  Accordion,
  Button,
  ButtonWidth,
  DatePicker,
  Divider,
  Drawer,
  FileInput,
  Input,
  Label,
  Select,
  Switch,
} from "~/scalis-components/core";
import { useFieldArray, useForm } from "react-hook-form";
import { timezones } from "~/helper/timezones";
import { CalendarProviderEnum } from "~/bff/graphql/generated/graphql";
import { Body, BodySize } from "~/scalis-components/core/typography";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import { Field } from "./components/field/field";
import { Editor } from "~/scalis-components/core/input/editor/components/editor/editor";
import {
  updateDate,
  validateTimeInput,
} from "./schedule-interview-drawer.utils";
import parse from "date-fns/parse";
import { format } from "date-fns";
import isSameDay from "date-fns/isSameDay";
import { LocationField } from "~/scalis-components/team/registration/location-field";

const timezoneOptions = timezones().map(timezone => ({
  label: timezone.formattedText,
  value: timezone.timeZone,
}));

const calendarOptions = [
  {
    label: "Google",
    value: CalendarProviderEnum.Google,
  },
  {
    label: "Microsoft",
    value: CalendarProviderEnum.Microsoft,
  },
];

const scorecardOptions = [
  {
    label: "Card 1",
    value: "1",
  },
  {
    label: "Card 2",
    value: "2",
  },
];

interface Stage {
  stageId: string;
  calendar: string;
  from: Date;
  to: Date;
  timezone: string;
  location?: string;
  googleMeetLink: boolean;
  scorecard?: string;
  interviewers: string[];
  details?: string;
  file?: FileList;
}

interface FormValues {
  stages: Stage[];
}

const defaultValues = {
  calendar: "",
  from: new Date(),
  to: new Date(),
  timezone: "",
  location: undefined,
  googleMeetLink: false,
  scorecard: undefined,
  interviewers: [],
  details: undefined,
  file: undefined,
};

export const ScheduleInterviewDrawer = ({
  isOpen,
  onOpenChange,
  onAnimationEnd,
  data,
}: ScheduleInterviewDrawerProps) => {
  const [date, setDate] = useState(new Date());
  const [showDetails, setShowDetails] = useState<Record<string, boolean>>({});
  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      stages: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stages",
  });

  const onSubmit = (data: FormValues) => {
    // write onsubmit
  };

  const { milestones, stageMap } = useMemo(() => {
    const milestones = normalizeMilestones(data);
    const stages = milestones.flatMap(milestone => milestone.stages);
    const stageMap = stages.reduce<Record<string, (typeof stages)[number]>>(
      (acc, next) => {
        acc[next.id] = next;
        return acc;
      },
      {},
    );

    return { milestones, stages, stageMap };
  }, [data]);

  const options = milestones.flatMap(milestone =>
    milestone.stages
      .map(stage => ({
        label: [milestone.name && milestoneNameMap[milestone.name], stage.name]
          .filter(Boolean)
          .join(" - "),
        value: stage.id,
      }))
      .filter(({ value }) => !fields.some(({ stageId }) => stageId === value)),
  );

  const watchedStages = watch("stages");
  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onAnimationEnd={onAnimationEnd}
      title="Schedule Interview"
      successButtonProps={{
        label: "Submit",
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Select
        value=""
        placeholder="Add Interview"
        options={options}
        setValue={(name, value) => {
          if (Array.isArray(value)) {
            return;
          }

          append({
            stageId: value,
            ...defaultValues,
          });
        }}
      />
      {fields.length > 0 && (
        <form
          className="mt-2 flex flex-col gap-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          {watchedStages.map((stage, index) => {
            const from = format(stage.from, "hh:mma");
            const to = format(stage.to, "hh:mma");
            return (
              <Accordion
                key={stage.stageId}
                title={stageMap[stage.stageId]?.name ?? ""}
                size="xlarge"
                defaultOpen
                collapsible
                padded={false}
                actions={[
                  {
                    icon: "fa-regular fa-xmark",
                    onClick: () => {
                      const nextShowDetails = { ...showDetails };
                      delete nextShowDetails[stage.stageId];

                      setShowDetails(nextShowDetails);
                      remove(index);
                    },
                  },
                ]}
              >
                <div className="flex flex-col gap-4 py-4">
                  <Field>
                    <Select
                      label="Calendar"
                      options={calendarOptions}
                      value={stage.calendar}
                      setValue={(name, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }

                        setValue(`stages.${index}.calendar`, value);
                      }}
                    />
                  </Field>
                  <Divider />
                  <Field>
                    <DatePicker
                      label="Date"
                      date={date}
                      onSelect={value => {
                        if (!value) {
                          return;
                        }

                        setDate(value);

                        if (!isSameDay(stage.from, value)) {
                          setValue(
                            `stages.${index}.from`,
                            updateDate(stage.from, value),
                          );
                        }

                        if (!isSameDay(stage.to, value)) {
                          setValue(
                            `stages.${index}.to`,
                            updateDate(stage.to, value),
                          );
                        }
                      }}
                      ariaLabel="date"
                    />
                  </Field>
                  <Field>
                    <div>
                      <Label>Time</Label>
                      <div className="flex items-center gap-2">
                        <Input
                          placeholder="00:00am"
                          onChange={event => {
                            const { value } = event.target;

                            if (validateTimeInput(value)) {
                              setValue(
                                `stages.${index}.from`,
                                parse(value, "hh:mma", date),
                              );
                            }
                          }}
                        />
                        to
                        <Input
                          placeholder="00:00pm"
                          onChange={event => {
                            const { value } = event.target;
                            if (validateTimeInput(value)) {
                              setValue(
                                `stages.${index}.to`,
                                parse(value, "hh:mma", date),
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Field>
                  <Field>
                    <Select
                      label="Timezone"
                      searchPlaceholder="Search timezone"
                      options={timezoneOptions}
                      value={stage.timezone}
                      setValue={(name, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }

                        setValue(`stages.${index}.timezone`, value);
                      }}
                    />
                  </Field>
                  <Field>
                    <Label>Location (Optional)</Label>
                    <LocationField
                      name="location"
                      placeholder="Location"
                      setValue={(name, value) => {
                        setValue(`stages.${index}.location`, value.place_id);
                      }}
                    />
                  </Field>
                  <Field>
                    <div className="flex items-center gap-2">
                      <div className="flex h-6 w-8 items-center justify-center">
                        <Image
                          width={24}
                          height={18}
                          src={"/images/googlemeet.svg"}
                          alt="Google Meet icon"
                        />
                      </div>
                      <Body size={BodySize.small}>Google Meet Link</Body>
                      <Switch
                        onCheckedChange={value =>
                          setValue(`stages.${index}.googleMeetLink`, value)
                        }
                      />
                    </div>
                  </Field>
                  <Divider />
                  <Field>
                    <Select
                      label="Scorecard (Optional)"
                      options={scorecardOptions}
                      value={stage.scorecard ?? ""}
                      setValue={(name, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }

                        setValue(`stages.${index}.scorecard`, value);
                      }}
                    />
                  </Field>
                  <Divider />
                  <Field>
                    <UserSelect
                      label="Interview Team"
                      value={stage.interviewers}
                      options={options}
                      onChange={value =>
                        setValue(`stages.${index}.interviewers`, value)
                      }
                    />
                  </Field>
                  <Divider />
                  {showDetails[stage.stageId] ? (
                    <Field>
                      <Editor
                        setValue={(name, value) =>
                          setValue(`stages.${index}.details`, value)
                        }
                      />
                      <FileInput
                        label="Upload File"
                        isStandalone
                        buttonWidth={ButtonWidth.auto}
                        onFileChange={fileList =>
                          setValue(`stages.${index}.file`, fileList)
                        }
                      />
                    </Field>
                  ) : (
                    <Field>
                      <Button
                        type="button"
                        isStandalone
                        endAdornment="fa-regular fa-plus-circle"
                        onClick={() =>
                          setShowDetails(prev => ({
                            ...prev,
                            [stage.stageId]: true,
                          }))
                        }
                      >
                        Add Details
                      </Button>
                    </Field>
                  )}
                </div>
              </Accordion>
            );
          })}
        </form>
      )}
    </Drawer>
  );
};
