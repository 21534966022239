import { z } from "zod";
import { RejectionReasonEnum } from "~/bff/graphql/generated/graphql";
import { RejectCandidateStep } from "./reject-candidate-drawer.types";

export const rejectCandidateFormSchema = z.object({
  applicationIds: z
    .array(z.string())
    .min(1, "At least one candidate must be selected"),
  customMessage: z.string(),
  details: z.string().optional(),
  medalistDesignation: z.string().optional(),
  questions: z.array(
    z.object({
      questionId: z.number(),
      score: z.number().min(1).max(10).optional(),
    }),
  ),
  reason: z.nativeEnum(RejectionReasonEnum, {
    errorMap: () => ({ message: "Select a rejection reason" }),
  }),
  isDefaultMessage: z.boolean().optional(),
  templateMessageId: z.string().optional(),
});

export const MAX_STEP_NUMBER = Object.keys(RejectCandidateStep).length / 2;
