import { z } from "zod";

export const schedulerLinkSchema = z.object({
  calendar: z.string().min(1, "Select a calendar"),
  candidates: z.array(z.string()).min(1, "Select at least one candidate"),
  interviewTeam: z.array(z.string()).min(1, "Select at least one team member"),
  interviewLength: z.string().min(1, "Select an interview length"),
  custom_message: z.string().min(1, "Enter a message"),
  cb_default_message: z.boolean().optional(),
  default_message: z.string().optional(),
});
