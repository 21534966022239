import { useState } from "react";
import {
  GetCandidateApplicationQuery,
  GetRequisitionPipelineQuery,
  NameEnum,
} from "~/bff/graphql/generated/graphql";
import { Application } from "./kanban-view.types";

export interface SelectApplicationPayload {
  applicationMilestone: NameEnum;
  applicationStageId: string;
  application: Application;
  checked: boolean;
}

export const useSelectedApplications = () => {
  const [selectedMilestone, setSelectedMilestone] = useState<NameEnum>();
  const [selectedStageId, setSelectedStageId] = useState<string>();
  const [selectedApplications, setSelectedApplications] = useState<
    Application[]
  >([]);

  const selectApplication = ({
    applicationMilestone,
    applicationStageId,
    application,
    checked,
  }: SelectApplicationPayload) => {
    if (applicationStageId === selectedStageId) {
      setSelectedApplications(prevApplications =>
        checked
          ? [...prevApplications, application]
          : prevApplications.filter(({ id }) => id !== application.id),
      );

      return;
    }

    setSelectedStageId(applicationStageId);
    setSelectedMilestone(applicationMilestone);
    setSelectedApplications([application]);
  };

  return {
    selectedMilestone,
    selectedStageId,
    selectedApplications,
    selectApplication,
  };
};

const isDefined = <T>(data: T | null | undefined): data is T =>
  data !== null && data !== undefined;

interface RankedEntity {
  rankOrder?: number | null;
}

const sortByRankOrder = (a: RankedEntity, b: RankedEntity) => {
  if (isDefined(a.rankOrder) && isDefined(b.rankOrder)) {
    return a.rankOrder - b.rankOrder;
  }

  return 0;
};

export const normalizeMilestones = (
  data: GetRequisitionPipelineQuery | undefined,
) => {
  const [jobPipeline] = data?.GetRequisition?.jobPipelines ?? [];
  const templateMilestones =
    jobPipeline?.TemplatePipeline?.TemplateMilestones ?? [];

  return templateMilestones
    .map(({ id, name, rankOrder, TemplateStage }) => {
      if (!TemplateStage || !TemplateStage?.length) {
        return null;
      }

      return {
        id,
        name,
        rankOrder,
        stages: [...TemplateStage].sort(sortByRankOrder),
      };
    })
    .filter(isDefined)
    .sort(sortByRankOrder);
};

export const normalizeApplications = (
  data: GetCandidateApplicationQuery | undefined,
) => {
  return data?.GetCandidateApplication?.map(app => {
    const user = app.CandidateProfile?.ScalisUser;
    const jobInfo = [
      ...(user?.currentJob ?? []),
      ...(user?.mostRecentJob ?? []),
    ].find(({ jobTitle }) => jobTitle);

    const profile = {
      id: user?.id,
      jobSeekerId: user?.JobSeekerInfo?.jobSeekerId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      profileImage: user?.profileImage,
      jobTitle: jobInfo?.jobTitle,
      employerName: jobInfo?.employerName,
    };

    return {
      id: app.id,
      matchScore: app.matchScore,
      applicationStatus: app.applicationStatus,
      applicationType: app.applicationType,
      applicationTimestamp: app.applicationTimestamp,
      lastUpdatedStatusAt: app.lastUpdatedStatusAt,
      templateStageId: app.templateStageId,
      profile,
    };
  });
};
