import { NameEnum } from "~/bff/graphql/generated/graphql";
import { DrawerProps } from "~/scalis-components/core/drawer/drawer.types";
import { z } from "zod";
import {
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  FIELD_NAME_NOTIFICATION_RADIO,
} from "~/scalis-components/pipeline/candidate-drawer";
import { CheckedState } from "@radix-ui/react-checkbox";
import {
  FIELD_NAME_CANDIDATE_SELECT,
  FIELD_NAME_HOST_CALENDAR_SELECT,
  FIELD_NAME_INTERVIEW_LENGTH_SELECT,
  FIELD_NAME_RECRUITER_SELECT,
  FIELD_NAME_STAGE_SELECT,
} from "./advance-candidate-drawer.constants";
import { FIELD_NAME_SCHEDULE_RADIO } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/advance-candidate-drawer/components/schedule-radio/schedule-radio.constants";

export interface AdvanceCandidateDrawerProps extends DrawerProps {
  milestoneType?: NameEnum;
  requisitionId?: string;
  defaultValues?: Partial<AdvanceCandidateFormValues>;
}

export interface SourcingStageFormValues {
  default_message?: string;
  cb_default_message?: CheckedState;
  custom_message?: string;
  should_notify_candidate?: string;
  candidate?: string[];
}

export const createSourcingStageFormSchema = () => {
  return z
    .object({
      [FIELD_NAME_NOTIFICATION_RADIO]: z.string().optional(),
      [FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
      [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: z.boolean().optional(),
      [FIELD_NAME_DEFAULT_MESSAGE]: z.string().optional(),
      [FIELD_NAME_CANDIDATE_SELECT]: z.array(z.string()).optional(),
    })
    .superRefine(
      (
        {
          cb_default_message,
          default_message,
          custom_message,
          should_notify_candidate,
        },
        ctx: {
          addIssue: (arg0: {
            code: "custom";
            message: string;
            path: string[];
          }) => void;
        },
      ) => {
        if (!Number(should_notify_candidate)) return;

        //if not using a default message, check if the custom message has content
        if (!cb_default_message) {
          const parsedMessage = custom_message?.replace(/<[^>]*>/g, "");
          if (!parsedMessage) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Custom message is required",
              path: [FIELD_NAME_CUSTOM_MESSAGE],
            });
          }
        } else if (!default_message) {
          //if using a default message, check if it has content
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Default message is required",
            path: [FIELD_NAME_DEFAULT_MESSAGE],
          });
        }
      },
    );
};

export interface AdvanceCandidateFormValues {
  default_message?: string;
  cb_default_message?: CheckedState;
  custom_message?: string;
  should_notify_candidate?: string;
  host_calendar?: string[];
  candidate?: string[];
  stageId?: string;
  interview_schedule?: string;
  interview_length?: string;
  recruiter?: string[];
}

export const createAdvanceCandidateFormSchema = () => {
  return z
    .object({
      [FIELD_NAME_NOTIFICATION_RADIO]: z.string().optional(),
      [FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
      [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: z.boolean().optional(),
      [FIELD_NAME_DEFAULT_MESSAGE]: z.string().optional(),
      [FIELD_NAME_SCHEDULE_RADIO]: z.string().optional(),

      [FIELD_NAME_CANDIDATE_SELECT]: z.array(z.string()).optional(),
      [FIELD_NAME_STAGE_SELECT]: z.string().optional(),
      [FIELD_NAME_HOST_CALENDAR_SELECT]: z.array(z.string()).optional(),
      [FIELD_NAME_INTERVIEW_LENGTH_SELECT]: z.string().optional(),
      [FIELD_NAME_RECRUITER_SELECT]: z.array(z.string()).optional(),
    })
    .superRefine(
      (
        {
          cb_default_message,
          default_message,
          custom_message,
          should_notify_candidate,
        },
        ctx: {
          addIssue: (arg0: {
            code: "custom";
            message: string;
            path: string[];
          }) => void;
        },
      ) => {
        if (!Number(should_notify_candidate)) return;

        //if not using a default message, check if the custom message has content
        if (!cb_default_message) {
          const parsedMessage = custom_message?.replace(/<[^>]*>/g, "");
          if (!parsedMessage) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Custom message is required",
              path: [FIELD_NAME_CUSTOM_MESSAGE],
            });
          }
        } else if (!default_message) {
          //if using a default message, check if it has content
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Default message is required",
            path: [FIELD_NAME_DEFAULT_MESSAGE],
          });
        }
      },
    );
};
