import React from "react";
import { Select } from "../select";
import { UserSelectProps } from "./user-select.types";
import { Popover } from "./components/popover/popover";
import { UserInfo } from "../user-info/user-info";
import { ScrollArea } from "../scroll-area";
import { ButtonIcon } from "../button-icon/button-icon";

export const UserSelect = ({
  value,
  onChange,
  options,
  renderPopoverItemContent,
  renderListItemContent,
  isLoading,
  ...selectProps
}: UserSelectProps) => {
  const filteredOptions = options.filter(
    option => !value.includes(option.value),
  );

  return (
    <div>
      <Select
        {...selectProps}
        options={filteredOptions}
        value={value}
        disabled={isLoading || selectProps.disabled}
        popover={({ closePopover }) => (
          <Popover
            options={filteredOptions}
            renderItem={option => {
              const { label, email, profileImage } = option;

              return (
                <div
                  key={option.value}
                  className="rounded-lg p-2 hover:bg-neutral-20 active:bg-neutral-30"
                >
                  <UserInfo
                    onClick={() => {
                      onChange([...value, option.value]);
                      closePopover();
                    }}
                    fullName={label}
                    src={profileImage}
                    email={email}
                  >
                    {renderPopoverItemContent?.(option)}
                  </UserInfo>
                </div>
              );
            }}
            filter={(value, { label, email }) =>
              [label, email].some(entry => entry?.includes(value.toLowerCase()))
            }
          />
        )}
      />
      {value.length > 0 && (
        <ScrollArea className="max-h-[280px] overflow-auto">
          {value.map((id, index) => {
            const option = options.find(option => option.value === id);

            if (!option) return null;

            const { email, profileImage } = option;

            return (
              <div key={option.value} className="my-1 py-2">
                <UserInfo
                  fullName={option.label}
                  src={profileImage}
                  email={email}
                >
                  {renderListItemContent?.(option)}
                  <ButtonIcon
                    onClick={() => {
                      onChange(value.filter(id => id !== option.value));
                    }}
                    icon="fa-regular fa-xmark"
                  />
                </UserInfo>
              </div>
            );
          })}
        </ScrollArea>
      )}
    </div>
  );
};
