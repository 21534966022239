export const LABEL_STAGE_SELECT = "Stage";
export const LABEL_NOTIFICATION_RADIO = "Candidate Notification";

export const INVITE_CANDIDATE_RADIO_OPTIONS = [
  {
    value: "0",
    label:
      "Advance candidate without inviting them to apply (candidate will not receive email notification)",
  },
  {
    value: "1",
    label: "Invite candidate to apply",
  },
];

export const NOTIFICATION_RADIO_OPTIONS = [
  {
    value: "0",
    label: "Do Not Notify Candidate",
  },
  {
    value: "1",
    label: "Notify Candidate",
  },
];

export const LABEL_NOTIFICATION_INVITATION_RADIO = "Invite Candidate?";

export const POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER = "advance_candidate_drawer";

export const FIELD_NAME_CANDIDATE_SELECT = "candidate";
export const FIELD_NAME_STAGE_SELECT = "stageId";
export const FIELD_NAME_HOST_CALENDAR_SELECT = "host_calendar";
export const FIELD_NAME_INTERVIEW_LENGTH_SELECT = "interview_length";
export const FIELD_NAME_RECRUITER_SELECT = "recruiter";
