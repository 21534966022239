import {
  GetRequisitionPipelineQuery,
  NameEnum,
  TemplateStageVisibilityEnum,
} from "~/bff/graphql/generated/graphql";

export const requisitionData: GetRequisitionPipelineQuery = {
  GetRequisition: {
    __typename: "Requisition",
    id: "4",
    companyId: 8,
    jobName: "linen",
    jobListings: [
      {
        __typename: "JobListing",
        id: 2,
        acceptingCandidates: true,
      },
      {
        __typename: "JobListing",
        id: 11,
        acceptingCandidates: false,
      },
      {
        __typename: "JobListing",
        id: 12,
        acceptingCandidates: true,
      },
      {
        __typename: "JobListing",
        id: 4,
        acceptingCandidates: false,
      },
      {
        __typename: "JobListing",
        id: 16,
        acceptingCandidates: false,
      },
      {
        __typename: "JobListing",
        id: 20,
        acceptingCandidates: true,
      },
    ],
    jobPipelines: [
      {
        __typename: "JobPipeline",
        id: "9",
        title: "congress",
        TemplatePipeline: {
          __typename: "TemplatePipeline",
          id: "2",
          name: "networking",
          TemplateMilestones: [
            {
              __typename: "TemplateMilestone",
              id: "26",
              name: NameEnum.ApplicationReview,
              rankOrder: 2,
              TemplateStage: [
                {
                  __typename: "TemplateStage",
                  id: "26",
                  name: "Application Review",
                  rankOrder: 1,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
              ],
            },
            {
              __typename: "TemplateMilestone",
              id: "8",
              name: NameEnum.Interviewing,
              rankOrder: 4,
              TemplateStage: [
                {
                  __typename: "TemplateStage",
                  id: "13",
                  name: "Screening",
                  rankOrder: 1,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
                {
                  __typename: "TemplateStage",
                  id: "28",
                  name: "Tech assessment",
                  rankOrder: 2,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
                {
                  __typename: "TemplateStage",
                  id: "27",
                  name: "Interviewing",
                  rankOrder: 1,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
              ],
            },
            {
              __typename: "TemplateMilestone",
              id: "9",
              name: NameEnum.Offer,
              rankOrder: 5,
              TemplateStage: [
                {
                  __typename: "TemplateStage",
                  id: "15",
                  name: "Offer",
                  rankOrder: 5,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
              ],
            },
            {
              __typename: "TemplateMilestone",
              id: "2",
              name: NameEnum.Sourcing,
              rankOrder: 1,
              TemplateStage: [
                {
                  __typename: "TemplateStage",
                  id: "14",
                  name: "Sourcing",
                  rankOrder: 4,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
              ],
            },
            {
              __typename: "TemplateMilestone",
              id: "10",
              name: NameEnum.PreScreen,
              rankOrder: 3,
              TemplateStage: [
                {
                  __typename: "TemplateStage",
                  id: "11",
                  name: "Pre-Screen",
                  rankOrder: 1,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
              ],
            },
            {
              __typename: "TemplateMilestone",
              id: "11",
              name: NameEnum.Hired,
              rankOrder: 5,
              TemplateStage: [
                {
                  __typename: "TemplateStage",
                  id: "12",
                  name: "Hired",
                  rankOrder: 2,
                  visibility: TemplateStageVisibilityEnum.VisibleOnlyInternally,
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
