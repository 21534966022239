import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, useForm, UseFormTrigger } from "react-hook-form";
import { TemplateMessage } from "~/bff/graphql/generated/graphql";
import { Drawer, Select } from "~/scalis-components/core";
import { SuccessButtonProps } from "~/scalis-components/core/drawer/drawer.types";
import {
  Caption,
  CaptionColor,
  Title,
  TitleSize,
} from "~/scalis-components/core/typography";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import { EmployerMessage } from "../candidate-drawer";
import { CalendarNotConnectedAlert } from "./components";
import { schedulerLinkSchema } from "./scheduler-link-drawer.constants";
import {
  DEFAULT_MESSAGES_MOCK,
  INTERVIEW_TEAM_MOCK,
} from "./scheduler-link-drawer.mocks";
import {
  SchedulerLinkDrawerProps,
  SchedulerLinkFormValues,
} from "./scheduler-link-drawer.types";

export const SchedulerLinkDrawer = ({
  requisitionId,
  defaultValues,
  ...props
}: SchedulerLinkDrawerProps) => {
  const { handleSubmit, formState, watch, trigger, setValue } =
    useForm<SchedulerLinkFormValues>({
      resolver: zodResolver(schedulerLinkSchema),
      defaultValues: {
        calendar: "",
        candidates: [],
        interviewTeam: [],
        interviewLength: "",
        custom_message: "",
        cb_default_message: false,
        default_message: "",
        ...defaultValues,
      },
    });

  const successButtonProps: SuccessButtonProps = {
    label: "Next",
    type: "submit",
    form: "interview-link-form",
  };

  const onSubmit = (data: SchedulerLinkFormValues) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.custom_message, "text/html");
    const cleanData = {
      ...data,
      message: doc.body.textContent || "",
    };
    console.log("Form data:", cleanData);
  };

  const cancelButtonProps = {
    label: "Cancel",
  };

  /* TODO: get this info from the right place */
  const isCalendarConnectedMock = true;

  /* TODO: integrate this */
  const selectedInterviewers = INTERVIEW_TEAM_MOCK.filter(option =>
    watch("interviewTeam")?.includes(option.value),
  );
  const hasInterviewerWithCalendarNotConnected = selectedInterviewers.some(
    interviewer => !interviewer.calendarConnected,
  );

  return (
    <Drawer
      {...props}
      title="Schedule"
      contentClassName="p-4"
      endAlignedFooterActions
      successButtonProps={successButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      {!isCalendarConnectedMock && <CalendarNotConnectedAlert />}

      {isCalendarConnectedMock && (
        <div className="flex flex-col gap-6">
          <Title size={TitleSize.extraSmall}>Scheduler Link</Title>

          <form
            id="interview-link-form"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <Select
              name="calendar"
              value={watch("calendar")}
              setValue={(name, value) =>
                setValue(name as keyof SchedulerLinkFormValues, value)
              }
              label="Calendar"
              options={[
                { label: "Option 1", value: "1" },
                { label: "Option 2", value: "2" },
              ]}
              error={formState.errors.calendar?.message?.toString()}
            />

            <UserSelect
              name="candidates"
              value={watch("candidates")}
              onChange={value =>
                setValue("candidates", value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                })
              }
              label="Candidates"
              options={[
                {
                  label: "Candidate 1",
                  value: "1",
                  email: "candidate1@example.com",
                  calendarConnected: true,
                },
                {
                  label: "Candidate 2",
                  value: "2",
                  email: "candidate2@example.com",
                  calendarConnected: false,
                },
              ]}
              error={formState.errors.candidates?.message?.toString()}
              placeholder="Select candidate(s)"
              emptyTerm="No candidates available"
              renderPopoverItemContent={option => {
                /* TODO: use right field here */
                if (!option.calendarConnected) {
                  return (
                    <Caption color={CaptionColor.secondary}>
                      Calendar not connected
                    </Caption>
                  );
                }
              }}
            />

            <UserSelect
              name="interviewTeam"
              value={watch("interviewTeam")}
              onChange={value =>
                setValue("interviewTeam", value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                })
              }
              label="Interview Team"
              options={INTERVIEW_TEAM_MOCK}
              error={formState.errors.interviewTeam?.message?.toString()}
              placeholder="Select team"
              emptyTerm="No team members available"
            />

            {hasInterviewerWithCalendarNotConnected && (
              <CalendarNotConnectedAlert />
            )}

            <Select
              name="interviewLength"
              value={watch("interviewLength")}
              setValue={(name, value) =>
                setValue(name as keyof SchedulerLinkFormValues, value)
              }
              label="Interview Length"
              options={[
                { label: "15 minutes", value: "15" },
                { label: "30 minutes", value: "30" },
                { label: "45 minutes", value: "45" },
                { label: "60 minutes", value: "60" },
                { label: "90 minutes", value: "90" },
                { label: "2 hours", value: "120" },
                { label: "2.5 hours", value: "150" },
                { label: "3 hours", value: "180" },
                { label: "3.5 hours", value: "210" },
                { label: "4 hours", value: "240" },
              ]}
              placeholder="Select an option"
              emptyTerm="Interview Length"
              error={formState.errors.interviewLength?.message?.toString()}
            />

            <div className="flex flex-col gap-4">
              <Title size={TitleSize.extraSmall}>Message</Title>

              <EmployerMessage
                defaultMessages={
                  DEFAULT_MESSAGES_MOCK as unknown as TemplateMessage[]
                }
                onValueChange={(name: string, value: string) => {
                  setValue(name as keyof SchedulerLinkFormValues, value);

                  /* This does not work, but leaving it here for future reference */
                  /* const selectedMessage = DEFAULT_MESSAGES_MOCK.find(
                  msg => msg.id === Number(value),
                );

                if (selectedMessage) {
                  const htmlMessage = `<p class="m-0 mb-2 relative" dir="ltr"><span style="white-space: pre-wrap;">${selectedMessage.message}</span></p>`;

                  setValue("custom_message", htmlMessage, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                } */
                }}
                placeholder="Insert here your message"
                triggerValidation={
                  trigger as unknown as UseFormTrigger<FieldValues>
                }
                customMessage={watch("custom_message")}
                defaultMessage={watch("default_message")}
                defaultMessageError={formState.errors.default_message?.message}
                customMessageError={formState.errors.custom_message?.message}
              />
            </div>
          </form>
        </div>
      )}
    </Drawer>
  );
};
