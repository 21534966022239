import React from "react";
import { Alert } from "~/scalis-components/core/alert/alert";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import { getCandidateOptions } from "../../pipeline.utils";
import { DiscardCandidateFormProps } from "./discard-candidate-form.types";

export const DiscardCandidateForm = ({
  form,
  candidates,
  isLoadingCandidates,
  isLoading,
}: DiscardCandidateFormProps) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = form;

  return (
    <div className="flex h-full flex-col gap-6 overflow-auto">
      <UserSelect
        name="applicationIds"
        label="Candidate(s) being rejected"
        emptyTerm="candidate(s)"
        options={getCandidateOptions(candidates)}
        value={watch("applicationIds")}
        onChange={value => {
          setValue("applicationIds", value);
        }}
        error={errors.applicationIds?.message}
        isLoading={isLoading || isLoadingCandidates}
      />

      <Alert
        title="Important!"
        message="Candidates will NOT receive notice of this move as they are in the Sourcing stage of the job. As a result, they have not entered the formal application process."
      />
    </div>
  );
};
