import { isEmpty, slice } from "lodash";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  CalendarSettings,
  CandidateApplication,
  CandidateInterviewStatusEnum,
  CompanyUser,
  MessageOwnerEnum,
  MessageTypeEnum,
  useListTemplateMessagesQuery,
} from "~/bff/graphql/generated/graphql";
import { ScheduleRadio } from "~/scalis-components";
import { Alert } from "~/scalis-components/core";
import { Select } from "~/scalis-components/core/select/select";
import { Caption, CaptionColor } from "~/scalis-components/core/typography";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import { options } from "~/scalis-components/core/user-select/user-select.mocks";
import { UserSelectOption } from "~/scalis-components/core/user-select/user-select.types";
import {
  EmployerMessage,
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  FIELD_NAME_NOTIFICATION_RADIO,
  NotificationRadio,
} from "~/scalis-components/pipeline/candidate-drawer";
import {
  FIELD_NAME_SCHEDULE_RADIO,
  ScheduleOptionEnum,
} from "~/src/app/company/(dashboard)/(applications)/pipeline/components/advance-candidate-drawer/components/schedule-radio/schedule-radio.constants";
import { getCandidateOptions } from "../../pipeline.utils";
import { useAdvanceCandidateDrawerContext } from "../advance-candidate-drawer-context";
import {
  FIELD_NAME_CANDIDATE_SELECT,
  FIELD_NAME_HOST_CALENDAR_SELECT,
  FIELD_NAME_INTERVIEW_LENGTH_SELECT,
  FIELD_NAME_RECRUITER_SELECT,
  FIELD_NAME_STAGE_SELECT,
  LABEL_NOTIFICATION_RADIO,
  LABEL_STAGE_SELECT,
  NOTIFICATION_RADIO_OPTIONS,
} from "../advance-candidate-drawer.constants";
import { AdvanceCandidateFormValues } from "../advance-candidate-drawer.types";

interface AdvanceCandidateFormProps {
  form: UseFormReturn<AdvanceCandidateFormValues>;
  stageOptions: Array<{ label: string; value: string | number }>;
  candidatesData: Array<CandidateApplication>;
  interviewers: Array<CompanyUser>;
  hostCalendarSettings: CalendarSettings | undefined;
}

export const AdvanceCandidateForm = (props: AdvanceCandidateFormProps) => {
  const {
    form,
    stageOptions,
    candidatesData,
    interviewers,
    hostCalendarSettings,
  } = props;
  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = form;

  const {
    isNotifyCandidate,
    setIsNotifyCandidate,
    showInterviewerSelection,
    setShowInterviewerSelection,
    isPublicStage,
  } = useAdvanceCandidateDrawerContext();

  const interviewSchedule = watch(FIELD_NAME_SCHEDULE_RADIO);
  const candidates = watch(FIELD_NAME_CANDIDATE_SELECT);
  const stageId = watch(FIELD_NAME_STAGE_SELECT);

  const candidateOptions = getCandidateOptions(candidatesData);

  const selectedCandidates = options.filter(option =>
    candidates?.includes(option.value),
  );
  const hostCalendar = options.find(option =>
    watch(FIELD_NAME_HOST_CALENDAR_SELECT)?.includes(option.value),
  );

  const selectedCandidateIds = selectedCandidates.map(
    candidate => candidate.value,
  );
  const filteredCandidatesData = candidatesData.filter(candidate =>
    selectedCandidateIds.includes(candidate.id),
  );
  const someCandidateAlreadyScheduled = filteredCandidatesData.some(candidate =>
    candidate.CandidateInterview?.some(
      interview =>
        interview.stageId === stageId &&
        interview.status === CandidateInterviewStatusEnum.InterviewScheduled,
    ),
  );

  const { data: templateMessagesData } = useListTemplateMessagesQuery({
    variables: {
      messageOwner: MessageOwnerEnum.Company,
    },
  });

  const templateMessages =
    templateMessagesData?.ListTemplateMessages?.filter(
      message =>
        message.messageOwner === MessageOwnerEnum.Company &&
        message.messageType === MessageTypeEnum.AdvanceCandidate,
    ) || [];

  const hostCalendarsOptions =
    hostCalendarSettings?.Calendars?.map(calendar => ({
      label: calendar.name,
      value: calendar.id,
      email: calendar.nylasCalendarId,
    })) || [];

  const interviewersOptions =
    interviewers?.map(interviewer => ({
      label: `${interviewer.UserInvitation?.ScalisUser?.firstName} ${interviewer.UserInvitation?.ScalisUser?.lastName}`,
      value: interviewer.id,
      email: interviewer.UserInvitation?.email,
      calendarConnected: !!interviewer.CalendarSettings,
    })) || [];
  useEffect(() => {
    setShowInterviewerSelection?.(
      interviewSchedule === ScheduleOptionEnum.SendSchedulerLink.toString(),
    );
  }, [interviewSchedule]);

  return (
    <div className="flex h-full flex-col justify-between gap-6 overflow-auto">
      <div className="flex flex-col gap-6">
        <UserSelect
          name={FIELD_NAME_CANDIDATE_SELECT}
          label="Candidate(s)"
          emptyTerm="candidate(s)"
          options={candidateOptions as any}
          value={watch(FIELD_NAME_CANDIDATE_SELECT) ?? []}
          onChange={(value: string[]) => {
            setValue(FIELD_NAME_CANDIDATE_SELECT, value);
          }}
          renderPopoverItemContent={option => {
            if (!option.calendarConnected) {
              return (
                <Caption color={CaptionColor.secondary}>
                  Calendar not connected
                </Caption>
              );
            }
          }}
        />

        <Select
          name={FIELD_NAME_STAGE_SELECT}
          label={LABEL_STAGE_SELECT}
          options={stageOptions as any}
          value={watch(FIELD_NAME_STAGE_SELECT) ?? ""}
          setValue={(name, value) => {
            setValue(name as keyof AdvanceCandidateFormValues, value);
          }}
        />

        {!isPublicStage && (
          <>
            {/* TODO: Only render the rest of the form if the selected stage is public and not internal */}
            <NotificationRadio
              label={LABEL_NOTIFICATION_RADIO}
              onValueChange={(name: string, value: string) => {
                setValue(name as keyof AdvanceCandidateFormValues, value);
                setIsNotifyCandidate?.(!!parseInt(value));
              }}
              value={watch(FIELD_NAME_NOTIFICATION_RADIO)}
              notificationOptions={NOTIFICATION_RADIO_OPTIONS}
            />

            {isNotifyCandidate && (
              <>
                <EmployerMessage
                  // TODO: add the default messages once they are pulled from backend
                  defaultMessages={templateMessages}
                  onValueChange={setValue}
                  triggerValidation={trigger}
                  customMessage={watch(FIELD_NAME_CUSTOM_MESSAGE)}
                  defaultMessage={watch(FIELD_NAME_DEFAULT_MESSAGE)}
                  defaultMessageError={
                    errors[FIELD_NAME_DEFAULT_MESSAGE]?.message
                  }
                  customMessageError={
                    errors[FIELD_NAME_CUSTOM_MESSAGE]?.message
                  }
                />

                <ScheduleRadio
                  value={interviewSchedule}
                  onValueChange={(name: string, value: string) => {
                    setValue(name as keyof AdvanceCandidateFormValues, value);
                  }}
                />

                {showInterviewerSelection && (
                  <>
                    {/* TODO: this alert should only appear if the host calendar is not connected yet
                  (get this from the right source) */}
                    {!hostCalendarSettings && (
                      <Alert
                        title="Important!"
                        message="To send a scheduler link please connect your calendar to SCALIS."
                      />
                    )}
                    {/* TODO: Add right options (it's reusing the options for mocks sake) */}
                    <UserSelect
                      name={FIELD_NAME_HOST_CALENDAR_SELECT}
                      value={watch(FIELD_NAME_HOST_CALENDAR_SELECT) as string[]}
                      onChange={(value: string[]) => {
                        if (isEmpty(value)) {
                          setValue(FIELD_NAME_HOST_CALENDAR_SELECT, []);
                        } else {
                          setValue(
                            FIELD_NAME_HOST_CALENDAR_SELECT,
                            slice(value, -1),
                          );
                        }
                      }}
                      label="Host Calendar"
                      emptyTerm="calendar"
                      options={
                        hostCalendarsOptions as unknown as UserSelectOption[]
                      }
                    />
                    {/* TODO: Add right options (it's reusing the options for mocks sake) */}
                    <UserSelect
                      name={FIELD_NAME_RECRUITER_SELECT}
                      value={watch(FIELD_NAME_RECRUITER_SELECT) as string[]}
                      onChange={(value: string[]) => {
                        setValue(FIELD_NAME_RECRUITER_SELECT, value);
                      }}
                      label="Assign Interview to User(s)"
                      emptyTerm="recruiter(s)"
                      options={
                        interviewersOptions as unknown as UserSelectOption[]
                      }
                    />
                    {/* TODO: Add right options */}
                    <Select
                      options={[
                        { label: "1 hour", value: "1" },
                        { label: "2 hours", value: "2" },
                      ]}
                      value={
                        watch(
                          FIELD_NAME_INTERVIEW_LENGTH_SELECT as keyof AdvanceCandidateFormValues,
                        ) as string | string[]
                      }
                      onChange={value =>
                        setValue(
                          FIELD_NAME_INTERVIEW_LENGTH_SELECT as keyof AdvanceCandidateFormValues,
                          value,
                        )
                      }
                      name={FIELD_NAME_INTERVIEW_LENGTH_SELECT}
                      label="Interview length"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* alert container */}
      <div className="flex flex-col gap-2">
        {!isPublicStage && (
          <Alert
            title="Important!"
            message="Candidates advanced to an Internal Stage will not be notified of the action as these stages are only visible to your team. Candidates cannot see these stages in their dashboard."
          />
        )}

        {/* TODO: render this if a candidate is already scheduled for an interview in the selected stage */}
        {someCandidateAlreadyScheduled && (
          <Alert
            title="Important!"
            message="Candidates will NOT receive notice of this move unless they are invited to create a SCALIS profile and apply to the job."
          />
        )}
      </div>
    </div>
  );
};
