import { z } from "zod";
import { DrawerProps } from "~/scalis-components/core/drawer/drawer.types";
import { rejectCandidateFormSchema } from "./reject-candidate-drawer.constants";

export type RejectCandidateFormValues = z.infer<
  typeof rejectCandidateFormSchema
>;

export interface RejectCandidateDrawerProps extends DrawerProps {
  requisitionId?: number;
  defaultValues?: Partial<RejectCandidateFormValues>;
}

export enum RejectCandidateStep {
  RejectCandidate = 1,
  CandidateRating = 2,
}
