export const validateTimeInput = (value: string) => {
  const errors = [];
  const match = value.match(/(\d+):(\d+)(am|pm)/i);

  if (!match) {
    return false;
  }

  const [_, rawHours, rawMinutes, rawPeriod] = match;

  const hours = Number(rawHours);
  const minutes = Number(rawMinutes);
  const midday = rawPeriod;

  if (Number.isNaN(hours)) {
    errors.push("Hours should be a number");
  }
  if (Number.isNaN(minutes)) {
    errors.push("Minutes should be a number");
  }
  if (!["am", "pm"].includes(midday.toLowerCase())) {
    errors.push("Time period should be either 'am' or 'pm'");
  }

  if (errors.length) {
    return false;
  }

  if (hours > 12 || hours < 1) {
    errors.push("Hours may have value from 1 to 12");
  }

  if (minutes < 0 || minutes >= 60) {
    errors.push("Minutes may have value from 0 to 59");
  }

  if (errors.length) {
    return false;
  }

  return true;
};

export const updateDate = (date: Date, newDate: Date) => {
  const updatedDate = new Date(newDate);
  updatedDate.setHours(date.getHours());
  updatedDate.setMinutes(date.getMinutes());
  updatedDate.setSeconds(date.getSeconds());
  updatedDate.setMilliseconds(date.getMilliseconds());
  return updatedDate;
};
