import { useSession } from "next-auth/react";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  MessageOwnerEnum,
  MessageTypeEnum,
  useGetJobListingQuery,
  useGetRequisitionsQuery,
  useGetTalentPoolQuery,
  useListTemplateMessagesQuery,
} from "~/bff/graphql/generated/graphql";
import { Select } from "~/scalis-components/core";
import { Title, TitleSize } from "~/scalis-components/core/typography";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import { EmployerMessage } from "~/scalis-components/pipeline/candidate-drawer";
import {
  MatchCandidateFormValues,
  SourcingOrInviteOption,
} from "../match-candidate-drawer.types";
import { MatchCandidateStageRadio } from "../match-candidate-stage-radio";

interface MatchCandidateFormProps {
  form: UseFormReturn<MatchCandidateFormValues>;
}

export const MatchCandidateForm = ({ form }: MatchCandidateFormProps) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = form;

  const session = useSession();
  const [requisitionId, setRequisitionId] = useState<string | undefined>(
    undefined,
  );

  const { data: templateMessagesData } = useListTemplateMessagesQuery({
    variables: {
      messageOwner: MessageOwnerEnum.Company,
    },
  });

  const templateMessages =
    templateMessagesData?.ListTemplateMessages?.filter(
      message =>
        message.messageOwner === MessageOwnerEnum.Company &&
        message.messageType === MessageTypeEnum.InviteToApply,
    ) || [];

  const companyId = session.data?.user?.workspace?.currentCompany?.id;

  const { data: requisitionsData } = useGetRequisitionsQuery({
    skip: !companyId,
    variables: {
      companyId,
    },
  });

  const requisitionsOptions =
    requisitionsData?.GetRequisitions?.map(requisition => ({
      label: requisition.jobName ?? "",
      value: requisition.id,
    })) ?? [];

  const { data: jobListingsData } = useGetJobListingQuery({
    skip: !requisitionId,
    variables: {
      jobRequisitionId: Number(requisitionId),
    },
  });

  const jobListingsOptions =
    jobListingsData?.GetJobListing?.map(jobListing => ({
      label: jobListing.externalTitle ?? "",
      value: jobListing.id?.toString() ?? "",
    })) ?? [];

  const { data: talentPoolData } = useGetTalentPoolQuery();

  const talentPoolOptions =
    talentPoolData?.GetCandidateProfile?.map(candidate => ({
      label:
        candidate.ScalisUser?.firstName && candidate.ScalisUser?.lastName
          ? `${candidate.ScalisUser.firstName} ${candidate.ScalisUser.lastName}`
          : `${candidate.UploadedCandidate?.firstName} ${candidate.UploadedCandidate?.lastName}`,
      value: candidate.id,
    })) ?? [];

  return (
    <div className="flex w-full flex-col gap-6 overflow-auto">
      <UserSelect
        name="candidateProfileIds"
        label="Candidate(s)"
        emptyTerm="candidate(s)"
        options={talentPoolOptions}
        value={watch("candidateProfileIds")}
        onChange={value => setValue("candidateProfileIds", value)}
      />

      <div className="flex flex-col gap-4">
        <Title size={TitleSize.extraSmall}>Job</Title>

        <Select
          name="requisitionId"
          value={watch("requisitionId")}
          setValue={(_, value) => {
            const strVal = Array.isArray(value) ? value[0] : value;
            setValue("requisitionId", strVal);
            setRequisitionId(strVal);
          }}
          label="Select other current job requisition"
          options={requisitionsOptions}
        />

        <Select
          name="jobListingId"
          value={watch("jobListingId")}
          label="Listing"
          setValue={(_, value) => {
            const strVal = Array.isArray(value) ? value[0] : value;
            setValue("jobListingId", strVal);
          }}
          options={jobListingsOptions}
          disabled={!watch("requisitionId")}
        />

        <MatchCandidateStageRadio
          name="sourcingOrInvite"
          value={String(watch("sourcingOrInvite"))}
          onValueChange={(_, value) => {
            setValue("sourcingOrInvite", value);
          }}
        />
      </div>

      {watch("sourcingOrInvite") === SourcingOrInviteOption.Invite && (
        <div className="flex flex-col gap-4">
          <Title size={TitleSize.extraSmall}>Message</Title>

          <EmployerMessage
            // TODO: add the default messages once they are pulled from backend
            defaultMessages={templateMessages}
            onValueChange={(name, value) => {
              setValue(name as keyof MatchCandidateFormValues, value);
            }}
            customMessage={watch("customMessage")}
            defaultMessage={watch("templateMessageId")}
            checkboxValue={watch("isDefaultMessage")}
            customMessageFieldName="customMessage"
            defaultMessageFieldName="templateMessageId"
            checkboxFieldName="isDefaultMessage"
            defaultMessageError={errors.templateMessageId?.message}
            customMessageError={errors.customMessage?.message}
          />
        </div>
      )}
    </div>
  );
};
