import {
  GetCandidatesApplicationsByStageQuery,
  CandidateApplication,
  ListTemplateMessagesQuery,
  MessageTypeEnum,
} from "~/bff/graphql/generated/graphql";
import {
  RejectCandidateFormValues,
  RejectCandidateStep,
} from "./reject-candidate-drawer.types";

export const getRejectionTemplateMessages = (
  templateMessagesData?: ListTemplateMessagesQuery,
) => {
  if (!templateMessagesData?.ListTemplateMessages) return [];

  return templateMessagesData.ListTemplateMessages.filter(
    ({ messageType }) => messageType === MessageTypeEnum.RejectCandidate,
  ).map(({ id, title }) => ({
    id: Number(id),
    title: title ?? "",
  }));
};

export const getStepFields = (
  step: RejectCandidateStep,
): (keyof RejectCandidateFormValues)[] => {
  switch (step) {
    case RejectCandidateStep.RejectCandidate:
      return [
        "applicationIds",
        "customMessage",
        "templateMessageId",
        "isDefaultMessage",
        "reason",
        "medalistDesignation",
        "details",
      ];
    case RejectCandidateStep.CandidateRating:
      return ["questions"];
  }
};
