import { z } from "zod";
import { DrawerProps } from "~/scalis-components/core/drawer/drawer.types";
import { matchCandidateFormSchema } from "./match-candidate-drawer.constants";

export type MatchCandidateFormValues = z.infer<typeof matchCandidateFormSchema>;

export interface MatchCandidateDrawerProps extends DrawerProps {
  defaultValues?: Partial<MatchCandidateFormValues>;
}

export enum SourcingOrInviteOption {
  Sourcing = "1",
  Invite = "2",
}
