import { UserSelectOption } from "./user-select.types";

export const options: UserSelectOption[] = [
  {
    label: "Thomas Anderson",
    value: "qwe",
    email: "thomas.anderson@metacortex.com",
    calendarConnected: true,
  },
  {
    label: "Trinity",
    value: "asd",
    email: "trinity@zionmail.org",
    calendarConnected: false,
  },
  {
    label: "Morpheus",
    value: "zxc",
    email: "niobe.jefferson@zionfleet.org",
    calendarConnected: false,
  },
  {
    label: "Niobe Jefferson",
    value: "rty",
    email: "morpheus@redpill.co",
    calendarConnected: false,
  },
  {
    label: "Agent Smith",
    value: "fgh",
    email: "agent.smith@matrixops.com",
    calendarConnected: true,
  },
  {
    label: "The Oracle",
    value: "vbn",
    email: "oracle@zionvisions.org",
    calendarConnected: false,
  },
];
