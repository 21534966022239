import React, { createContext, useState } from "react";

/* TODO: Fix this import, we do not import from @prisma/client */
import { DefaultMessages } from "@prisma/client";
import { TemplateStageVisibilityEnum } from "~/bff/graphql/generated/graphql";

export interface AdvanceCandidateDrawerContextProps {
  children: React.ReactNode;
}

export interface AdvanceCandidateDrawerContextState {
  stageVisibility?: TemplateStageVisibilityEnum;
  setStageVisibility?: (value: TemplateStageVisibilityEnum) => void;
  showInterviewerSelection?: boolean;
  setShowInterviewerSelection?: (value: boolean) => void;
  isPublicStage?: boolean;
  setIsPublicStage?: (value: boolean) => void;
  isNotifyCandidate?: boolean;
  setIsNotifyCandidate?: (value: boolean) => void;
  defaultMessages?: Partial<DefaultMessages>[];
  isLoadingApplication?: boolean;
}

export const AdvanceCandidateDrawerContext =
  createContext<AdvanceCandidateDrawerContextState>(
    {} as AdvanceCandidateDrawerContextState,
  );

export const AdvanceCandidateDrawerProvider: React.FC<
  AdvanceCandidateDrawerContextProps
> = ({ children }) => {
  const [stageVisibility, setStageVisibility] = React.useState(
    TemplateStageVisibilityEnum.VisibleOnlyInternally,
  );
  const [showInterviewerSelection, setShowInterviewerSelection] =
    useState(false);
  const [isNotifyCandidate, setIsNotifyCandidate] = React.useState(false);

  const isPublicStage =
    stageVisibility === TemplateStageVisibilityEnum.VisibleToCandidate;

  const contextValue: AdvanceCandidateDrawerContextState = React.useMemo(() => {
    return {
      stageVisibility,
      setStageVisibility,
      showInterviewerSelection,
      setShowInterviewerSelection,
      isPublicStage,
      isNotifyCandidate,
      setIsNotifyCandidate,
    };
  }, [
    isPublicStage,
    stageVisibility,
    showInterviewerSelection,
    isNotifyCandidate,
    setIsNotifyCandidate,
  ]);

  return (
    <AdvanceCandidateDrawerContext.Provider value={contextValue}>
      {children}
    </AdvanceCandidateDrawerContext.Provider>
  );
};

export const useAdvanceCandidateDrawerContext = () => {
  const context = React.useContext(AdvanceCandidateDrawerContext);
  if (!context) {
    throw new Error(
      "useAdvanceCandidateDrawerContext must be used within a AdvanceCandidateDrawerProvider",
    );
  }
  return context;
};
