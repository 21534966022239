import React from "react";
import { RadioGroup, RadioGroupType } from "~/scalis-components/core";
import { MATCH_CANDIDATES_RADIO_OPTIONS } from "../match-candidate-drawer.constants";
import { MatchCandidateStageRadioProps } from "./match-candidate-stage-radio.types";

export const MatchCandidateStageRadio = ({
  name,
  value,
  onValueChange,
}: MatchCandidateStageRadioProps) => {
  const handleValueChange = (value: string) => {
    onValueChange?.(name, value);
  };

  return (
    <RadioGroup
      name={name}
      options={MATCH_CANDIDATES_RADIO_OPTIONS}
      value={value}
      onValueChange={handleValueChange}
      optionsContainerClassName="flex flex-col gap-2"
      type={RadioGroupType.Highlited}
    />
  );
};
