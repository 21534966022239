import { castArray } from "lodash";
import { NameEnum } from "~/bff/graphql/generated/graphql";
import { ButtonKind } from "~/scalis-components/core/button";
import { TableColorTypes } from "~/scalis-components/core/list-view/list-view.types";
import { useApplicationActionsDrawersContext } from "~/scalis-components/pipeline/application-actions-drawers-context";
import { ActionBarItem } from "~/scalis-components/pipeline/list-view/candidate-action-bar/candidate-action-bar.types";
import { Candidate } from "~/scalis-components/pipeline/list-view/candidate-list-view";
import { Routes } from "~/src/app/_constants/routes";
import { requisitionData as SCHEDULE_INTERVIEW_MOCK } from "~/src/app/company/(dashboard)/pipeline/components/schedule-interview-drawer";
import {
  MatchTojobProps,
  MilestoneProps,
} from "./candidate-list-view-utils.types";

export const actionItemDetails = {
  chat: {
    label: "Chat",
  },
  scheduleInterview: {
    label: "Schedule Interview",
  },
  advance: {
    label: "Advance",
  },
  reject: {
    label: "Reject",
  },
  makeOffer: {
    label: "Make offer",
  },
  matchToJob: {
    label: "Match to job",
  },
  sendSchedulerLink: {
    label: "Send Scheduler Link",
  },
  sendToHris: {
    label: "Send to HRIS",
  },
};

const isScalisProfile = (candidate?: Candidate) => !!candidate?.seekerId;

export const useRowActionsMenuItems = (props?: MilestoneProps) => {
  const { showDrawer } = useApplicationActionsDrawersContext();

  const chat = {
    label: actionItemDetails.chat.label,
    href: Routes.company.messaging,
    id: actionItemDetails.chat.label,
    icon: "fa-regular fa-message-lines",
    renderIf: isScalisProfile,
  };

  const makeOffer = {
    label: actionItemDetails.makeOffer.label,
    onClick: () => {},
    id: actionItemDetails.makeOffer.label,
    icon: "fa-regular fa-file-signature",
  };

  const advance = {
    label: actionItemDetails.advance.label,
    onClick: (candidates: Candidate | Candidate[]) => {
      const candidatesList = castArray(candidates);

      return showDrawer({
        id: "advanceCandidate",
        props: {
          milestoneType: props?.milestoneType ?? undefined,
          requisitionId: String(props?.requisitionId),
          defaultValues: {
            candidate: candidatesList.map(candidate => String(candidate.id)),
            stageId: props?.nextStageId?.toString() ?? undefined,
          },
        },
      });
    },
    id: actionItemDetails.advance.label,
    icon: "fa-regular fa-circle-check",
  };

  const scheduleInterview = {
    label: actionItemDetails.scheduleInterview.label,
    onClick: (candidates: Candidate | Candidate[]) => {
      const candidatesList = castArray(candidates);

      return showDrawer({
        id: "scheduleOption",
        props: {
          data: SCHEDULE_INTERVIEW_MOCK,
          requisitionId: String(props?.requisitionId),
          defaultValues: {
            candidates: candidatesList.map(candidate => String(candidate.id)),
          },
        },
      });
    },
    id: actionItemDetails.scheduleInterview.label,
    icon: "fa-regular fa-calendar-day",
  };

  const reject = {
    label: actionItemDetails.reject.label,
    onClick: (candidates: Candidate | Candidate[]) => {
      const candidatesList = castArray(candidates);
      const milestoneType = props?.milestoneType;
      const drawerProps = {
        requisitionId: props?.requisitionId,
        defaultValues: {
          applicationIds: candidatesList.map(candidate => String(candidate.id)),
        },
      };

      if (milestoneType === NameEnum.Sourcing) {
        return showDrawer({ id: "discardCandidate", props: drawerProps });
      }

      return showDrawer({ id: "rejectCandidate", props: drawerProps });
    },
    id: actionItemDetails.reject.label,
    icon: "fa-regular fa-circle-x",
  };

  const matchToJob = {
    label: actionItemDetails.matchToJob.label,
    onClick: (candidates: Candidate | Candidate[]) => {
      const candidatesList = castArray(candidates);

      return showDrawer({
        id: "matchCandidate",
        props: {
          defaultValues: {
            candidateProfileIds: candidatesList.map(candidate =>
              String(candidate.profileId),
            ),
          },
        },
      });
    },
    id: actionItemDetails.matchToJob.label,
    icon: "fa-regular fa-briefcase",
  };

  const sendToHris = {
    label: actionItemDetails.sendToHris.label,
    onClick: () => {},
    id: actionItemDetails.sendToHris.label,
    icon: "fa-regular fa-bolt",
  };

  return {
    chat,
    makeOffer,
    advance,
    reject,
    matchToJob,
    scheduleInterview,
    sendToHris,
  };
};

export const useRowActionsBarItems = (props?: MilestoneProps) => {
  const {
    matchToJob,
    chat,
    makeOffer,
    advance,
    reject,
    scheduleInterview,
    sendToHris,
  } = useRowActionsMenuItems(props);
  const { showDrawer } = useApplicationActionsDrawersContext();

  const chatBar = {
    ...chat,
    endAdornment: chat.icon,
    kind: ButtonKind.tertiary,
  };

  const makeOfferBar = {
    ...makeOffer,
    endAdornment: makeOffer.icon,
    kind: ButtonKind.tertiary,
  };

  const advanceBar = {
    ...advance,
    endAdornment: advance.icon,
    kind: ButtonKind.tertiary,
  };

  const scheduleInterviewBar = {
    ...scheduleInterview,
    endAdornment: scheduleInterview.icon,
    kind: ButtonKind.tertiary,
  };

  const sendSchedulerLink = {
    label: actionItemDetails.sendSchedulerLink.label,
    onClick: (candidates: Candidate | Candidate[]) => {
      const candidatesList = castArray(candidates);

      return showDrawer({
        id: "schedulerLink",
        props: {
          requisitionId: String(props?.requisitionId),
          defaultValues: {
            candidates: candidatesList.map(candidate => String(candidate.id)),
          },
        },
      });
    },
    id: actionItemDetails.sendSchedulerLink.label,
    endAdornment: "fa-regular fa-calendar-day",
    kind: ButtonKind.tertiary,
  };

  const rejectBar = {
    ...reject,
    endAdornment: reject.icon,
    kind: ButtonKind.critical,
  };

  const matchToJobBar = {
    ...matchToJob,
    id: actionItemDetails.sendSchedulerLink.label,
    endAdornment: matchToJob.icon,
  };

  const sendToHrisBar = {
    ...sendToHris,
    endAdornment: sendToHris.icon,
    kind: ButtonKind.tertiary,
  };

  return {
    chat: chatBar,
    makeOffer: makeOfferBar,
    advance: advanceBar,
    reject: rejectBar,
    matchToJob: matchToJobBar,
    scheduleInterview: scheduleInterviewBar,
    sendSchedulerLink,
    sendToHris: sendToHrisBar,
  };
};

export const getMilestoneTypeProps = (props: MilestoneProps) => {
  const { advance, chat, reject, scheduleInterview, sendToHris } =
    useRowActionsMenuItems(props);
  const {
    chat: chatAction,
    advance: advanceAction,
    reject: rejectAction,
    scheduleInterview: scheduleInterviewAction,
    sendSchedulerLink,
    sendToHris: sendToHrisAction,
  } = useRowActionsBarItems(props);

  switch (props.milestoneType) {
    case NameEnum.Sourcing: {
      return {
        color: "01" as TableColorTypes,
        rowActionsMenuItems: [[chat, advance, reject]],
        actionsBarItems: {
          single: [chatAction, advanceAction, rejectAction] as ActionBarItem[],
          multiple: [advanceAction, rejectAction] as ActionBarItem[],
        },
      };
    }

    case NameEnum.ApplicationReview: {
      return {
        color: "02" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.PreScreen: {
      return {
        color: "03" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.Interviewing: {
      return {
        color: "04" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.Offer: {
      return {
        color: "05" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.Hired: {
      return {
        color: "06" as TableColorTypes,
        rowActionsMenuItems: [[chat, sendToHris]],
        actionsBarItems: {
          single: [chatAction, sendToHrisAction] as ActionBarItem[],
          multiple: [] as ActionBarItem[],
        },
      };
    }

    default: {
      return {
        color: "01" as TableColorTypes,
        rowActionsMenuItems: [[]],
        actionsBarItems: {
          single: [] as ActionBarItem[],
          multiple: [] as ActionBarItem[],
        },
      };
    }
  }
};

export const getTitle = (milestoneName: string, stageName: string) => {
  if (milestoneName === stageName) {
    return milestoneName;
  }

  return [stageName, milestoneName];
};
