import { UseFormReturn } from "react-hook-form";
import {
  CandidateApplication,
  TemplateMessage,
} from "~/bff/graphql/generated/graphql";
import { Alert } from "~/scalis-components/core";
import { Title, TitleSize } from "~/scalis-components/core/typography";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import { EmployerMessage } from "~/scalis-components/pipeline/candidate-drawer";
import { getCandidateOptions } from "../../pipeline.utils";
import { InternalNotes } from "../internal-notes/internal-notes";
import { RejectCandidateFormValues } from "../reject-candidate-drawer.types";

interface RejectCandidateFormProps {
  form: UseFormReturn<RejectCandidateFormValues>;
  isLoadingCandidates: boolean;
  candidates: CandidateApplication[];
  defaultMessages: { id: number; title: string }[];
}

export const RejectCandidateForm = ({
  form,
  isLoadingCandidates,
  candidates,
  defaultMessages,
}: RejectCandidateFormProps) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = form;

  return (
    <div className="flex h-full flex-col justify-between gap-6 overflow-auto">
      <div className="flex flex-col gap-6">
        <UserSelect
          name="applicationIds"
          label="Candidate(s) being rejected"
          emptyTerm="candidate(s)"
          options={getCandidateOptions(candidates)}
          value={watch("applicationIds")}
          onChange={value => {
            setValue("applicationIds", value);
          }}
          error={errors.applicationIds?.message}
          isLoading={isLoadingCandidates}
        />

        <div className="flex flex-col gap-4">
          <Title size={TitleSize.extraSmall}>Message</Title>

          <EmployerMessage
            defaultMessages={defaultMessages as unknown as TemplateMessage[]}
            onValueChange={(name, value) => {
              setValue(name as keyof RejectCandidateFormValues, value);
            }}
            customMessage={watch("customMessage")}
            defaultMessage={watch("templateMessageId")}
            checkboxValue={watch("isDefaultMessage")}
            customMessageFieldName="customMessage"
            defaultMessageFieldName="templateMessageId"
            checkboxFieldName="isDefaultMessage"
            defaultMessageError={errors.templateMessageId?.message}
            customMessageError={errors.customMessage?.message}
          />
        </div>

        <InternalNotes
          rejectionReasonValue={watch("reason")}
          rejectionReasonError={errors.reason?.message}
          medalistDesignationValue={watch("medalistDesignation")}
          medalistDesignationError={errors.medalistDesignation?.message}
          additionalDetailsValue={watch("details")}
          additionalDetailsError={errors.details?.message}
          onValueChange={(name, value) => {
            setValue(name as keyof RejectCandidateFormValues, value);
          }}
        />
      </div>

      <Alert
        title="Important!"
        message="Rejecting candidates cannot be undone."
      />
    </div>
  );
};
