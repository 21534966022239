import { ScheduleOptionButton } from "../schedule-option-button/schedule-option-button";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { ScheduleOption } from "../schedule-option-drawer.types";

interface ScheduleButtonGroupProps {
  value?: ScheduleOption;
  name: string;
  onValueChange: (value: ScheduleOption) => void;
}

export const ScheduleButtonGroup = ({
  value,
  name,
  onValueChange,
}: ScheduleButtonGroupProps) => {
  return (
    <RadioGroup.Root
      name={name}
      value={value}
      onValueChange={value => {
        if (
          value === ScheduleOption.LINK ||
          value === ScheduleOption.CALENDAR
        ) {
          onValueChange(value);
        }
      }}
      className="flex flex-col gap-2"
    >
      <RadioGroup.Item
        value={ScheduleOption.LINK}
        className="w-full focus:outline-none"
      >
        <ScheduleOptionButton
          icon="fa fa-link"
          title="Scheduler Link"
          description="Send a Link to the candidate for them to self-schedule into your calendar."
          isSelected={value === ScheduleOption.LINK}
        />
      </RadioGroup.Item>
      <RadioGroup.Item
        value={ScheduleOption.CALENDAR}
        className="w-full focus:outline-none"
      >
        <ScheduleOptionButton
          icon="fa fa-calendar"
          title="Manually Schedule"
          description="Schedule an interview and assign stakeholders to participate."
          isSelected={value === ScheduleOption.CALENDAR}
        />
      </RadioGroup.Item>
    </RadioGroup.Root>
  );
};
