import { Alert } from "~/scalis-components/core";

export const CalendarNotConnectedAlert = () => {
  return (
    <Alert
      title="Important!"
      message="To send a scheduler link please connect your calendar to SCALIS."
    />
  );
};
