import { z } from "zod";
import { FIELD_NAME_SCHEDULE_OPTION } from "./schedule-option-drawer.constants";
import { ScheduleOption } from "./schedule-option-drawer.types";

export interface ScheduleOptionFormValues {
  [FIELD_NAME_SCHEDULE_OPTION]?: ScheduleOption;
}

export const createScheduleOptionFormSchema = () => {
  return z.object({
    [FIELD_NAME_SCHEDULE_OPTION]: z.nativeEnum(ScheduleOption).optional(),
  });
};
