import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MessageOwnerEnum,
  NameEnum,
  useGetCandidatesApplicationsByStageQuery,
  useListTemplateMessagesQuery,
} from "~/bff/graphql/generated/graphql";
import { Body, BodyColor, BodySize } from "~/scalis-components/core/typography";
import { Drawer } from "~/scalis-components/core";
import {
  SecondaryButtonProps,
  SuccessButtonProps,
} from "~/scalis-components/core/drawer/drawer.types";
import { getCandidatesApplications } from "../pipeline.utils";
import { useApplicationActionsDrawersContext } from "../application-actions-drawers-context";
import { RejectCandidateForm } from "./reject-candidate-form";
import { CandidateRatingForm } from "./candidate-rating-form";
import {
  rejectCandidateFormSchema,
  MAX_STEP_NUMBER,
} from "./reject-candidate-drawer.constants";
import {
  getRejectionTemplateMessages,
  getStepFields,
} from "./reject-candidate-drawer.utils";
import {
  RejectCandidateDrawerProps,
  RejectCandidateFormValues,
  RejectCandidateStep,
} from "./reject-candidate-drawer.types";

export const RejectCandidateDrawer = ({
  requisitionId,
  defaultValues,
  ...props
}: RejectCandidateDrawerProps) => {
  const { showDrawer } = useApplicationActionsDrawersContext();

  const [step, setStep] = useState(RejectCandidateStep.RejectCandidate);

  const form = useForm<RejectCandidateFormValues>({
    resolver: zodResolver(rejectCandidateFormSchema),
    defaultValues: {
      applicationIds: [],
      customMessage: undefined,
      templateMessageId: undefined,
      isDefaultMessage: false,
      reason: undefined,
      medalistDesignation: "",
      details: undefined,
      questions: [
        { questionId: 1, score: undefined },
        { questionId: 2, score: undefined },
        { questionId: 3, score: undefined },
        { questionId: 4, score: undefined },
      ],
      ...defaultValues,
    },
  });

  const { data: candidatesData, loading: isLoadingCandidates } =
    useGetCandidatesApplicationsByStageQuery({
      variables: {
        requisitionId: requisitionId,
        milestoneNotIn: [NameEnum.Sourcing],
      },
      skip: !requisitionId,
    });

  const { data: templateMessagesData } = useListTemplateMessagesQuery({
    variables: {
      messageOwner: MessageOwnerEnum.Company,
    },
  });

  const candidates = getCandidatesApplications(candidatesData);
  const rejectionTemplateMessages =
    getRejectionTemplateMessages(templateMessagesData);

  const handleSubmit = form.handleSubmit(async values => {
    props.onOpenChange?.(false);
    form.reset();
    setStep(RejectCandidateStep.RejectCandidate);

    showDrawer({ id: "rejectCandidateConfirmation", props: values });
  });

  const successButtonProps: SuccessButtonProps = {
    label: step === RejectCandidateStep.CandidateRating ? "Reject" : "Next",
    onClick: async () => {
      const stepFields = getStepFields(step);
      const isValid = await form.trigger(stepFields);
      if (!isValid) return;

      if (step < MAX_STEP_NUMBER) {
        setStep(prev => Math.min(prev + 1, MAX_STEP_NUMBER));
      } else {
        handleSubmit();
      }
    },
  };

  const secondaryButtonProps: SecondaryButtonProps = {
    label: "Back",
    onClick: () => {
      setStep(prev => Math.max(1, prev - 1));
    },
    hidden: step === 1,
  };

  const cancelButtonProps = {
    label: "Cancel",
    hidden: step === MAX_STEP_NUMBER,
  };

  const actions = [
    <Body key="step-num" size={BodySize.small} color={BodyColor.secondary}>
      Step {step} / {MAX_STEP_NUMBER}
    </Body>,
  ];

  return (
    <Drawer
      {...props}
      title="Reject Candidate"
      contentClassName="p-4"
      endAlignedFooterActions
      successButtonProps={successButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      cancelButtonProps={cancelButtonProps}
      actions={actions}
    >
      {step === RejectCandidateStep.RejectCandidate && (
        <RejectCandidateForm
          form={form}
          isLoadingCandidates={isLoadingCandidates}
          candidates={candidates}
          defaultMessages={rejectionTemplateMessages}
        />
      )}

      {step === RejectCandidateStep.CandidateRating && (
        <CandidateRatingForm form={form} />
      )}
    </Drawer>
  );
};
