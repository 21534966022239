import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  CountCandidateApplicationDocument,
  GetCandidateApplicationDocument,
  useInviteCandidatesToApplyMutation,
  useSendCandidatesToSourcingStageMutation,
} from "~/bff/graphql/generated/graphql";
import {
  Drawer,
  NotificationKind,
  useNotification,
} from "~/scalis-components/core";
import { SuccessButtonProps } from "~/scalis-components/core/drawer/drawer.types";
import { notificationErrorHandler } from "~/utils/error";
import { matchCandidateFormSchema } from "./match-candidate-drawer.constants";
import {
  MatchCandidateDrawerProps,
  MatchCandidateFormValues,
  SourcingOrInviteOption,
} from "./match-candidate-drawer.types";
import { MatchCandidateForm } from "./match-candidate-form";

export const MatchCandidateDrawer = ({
  defaultValues,
  ...props
}: MatchCandidateDrawerProps) => {
  const form = useForm<MatchCandidateFormValues>({
    resolver: zodResolver(matchCandidateFormSchema),
    defaultValues: {
      candidateProfileIds: [],
      customMessage: undefined,
      templateMessageId: undefined,
      isDefaultMessage: false,
      requisitionId: "",
      jobListingId: "",
      sourcingOrInvite: SourcingOrInviteOption.Sourcing,
      ...defaultValues,
    },
  });

  const { notification } = useNotification();
  const [inviteToApply] = useInviteCandidatesToApplyMutation();
  const [sendToSourcingStage] = useSendCandidatesToSourcingStageMutation();

  const successButtonProps: SuccessButtonProps = {
    label: "Confirm",
    onClick: form.handleSubmit(async values => {
      const candidateProfileIds = Array.from(
        new Set(values.candidateProfileIds.map(id => Number(id))),
      );
      if (values.sourcingOrInvite === SourcingOrInviteOption.Invite) {
        try {
          const response = await inviteToApply({
            variables: {
              input: {
                candidateProfileIds,
                jobListingId: Number(values.jobListingId),
                customMessage: !values.isDefaultMessage
                  ? values.customMessage
                  : undefined,
                notifyUploadedCandidates: true,
                templateMessageId: values.templateMessageId
                  ? Number(values.templateMessageId)
                  : undefined,
              },
            },
            refetchQueries: [
              CountCandidateApplicationDocument,
              GetCandidateApplicationDocument,
            ],
          });
          notification({
            title: "Success",
            description: `Candidates were successfully invited to apply.`,
            kind: NotificationKind.success,
          });
          console.log("response", response);
        } catch {
          notificationErrorHandler({ notification });
        }
      } else {
        try {
          const response = await sendToSourcingStage({
            variables: {
              input: {
                candidateProfileIds,
                jobListingId: Number(values.jobListingId),
              },
            },
            refetchQueries: [
              CountCandidateApplicationDocument,
              GetCandidateApplicationDocument,
            ],
          });
          notification({
            title: "Success",
            description: `Candidates were successfully sent to sourcing stage.`,
            kind: NotificationKind.success,
          });
          console.log("response", response);
        } catch {
          notificationErrorHandler({ notification });
        }
      }
    }),
  };

  const cancelButtonProps = {
    label: "Cancel",
  };

  return (
    <Drawer
      {...props}
      title="Match to Job"
      contentClassName="p-4"
      endAlignedFooterActions={true}
      successButtonProps={successButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <MatchCandidateForm form={form} />
    </Drawer>
  );
};
