import { DrawerProps } from "../../application-actions-drawers-context.types";
import { DrawerRendererProps } from "./drawer-renderer.types";

export const DrawerRenderer = <T extends DrawerProps>({
  drawer,
  children,
}: DrawerRendererProps<T>) => {
  if (!drawer) return null;
  return children(drawer);
};
