import React from "react";
import { RejectionReasonEnum } from "~/bff/graphql/generated/graphql";
import {
  rejectionReasonMap,
  generateOptionsFromEnumMap,
} from "~/utils/enum-mappers";
import { Title, TitleSize } from "~/scalis-components/core/typography";
import { Select } from "~/scalis-components/core";
import { CustomMessage } from "~/scalis-components/pipeline/candidate-drawer";
import { MEDALIST_DESIGNATION_OPTIONS } from "./internal-notes.constants";
interface InternalNotesProps {
  rejectionReasonValue?: RejectionReasonEnum;
  rejectionReasonError?: string;
  medalistDesignationValue?: string;
  medalistDesignationError?: string;
  additionalDetailsValue?: string;
  additionalDetailsError?: string;
  onValueChange: (name: string, value: string | string[]) => void;
}

export const InternalNotes = ({
  rejectionReasonValue,
  rejectionReasonError,
  medalistDesignationValue,
  medalistDesignationError,
  additionalDetailsValue,
  additionalDetailsError,
  onValueChange,
}: InternalNotesProps) => {
  return (
    <div className="flex flex-col gap-4">
      <Title size={TitleSize.extraSmall}>Internal Notes</Title>

      <Select
        name="reason"
        options={generateOptionsFromEnumMap(rejectionReasonMap)}
        value={String(rejectionReasonValue)}
        setValue={onValueChange}
        label="Rejection Reason"
        error={rejectionReasonError}
      />
      <Select
        name="medalistDesignation"
        options={MEDALIST_DESIGNATION_OPTIONS}
        value={medalistDesignationValue ?? ""}
        setValue={onValueChange}
        label="Medalist Designation (Optional)"
        error={medalistDesignationError}
      />
      <CustomMessage
        editorContentClassname="h-14"
        fieldName="details"
        onValueChange={onValueChange}
        value={additionalDetailsValue}
        label="Additional Details (Optional)"
        placeholder="Insert here additional details..."
        error={additionalDetailsError}
      />
    </div>
  );
};
