import { Drawer } from "~/scalis-components/core/drawer";
import { SuccessButtonProps } from "~/scalis-components/core/drawer/drawer.types";
import {
  ScheduleOption,
  ScheduleOptionDrawerProps,
} from "./schedule-option-drawer.types";
import { ScheduleButtonGroup } from "./schedule-option-group/schedule-option-group";
import { FIELD_NAME_SCHEDULE_OPTION } from "./schedule-option-drawer.constants";
import {
  createScheduleOptionFormSchema,
  ScheduleOptionFormValues,
} from "./schedule-option-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

export const ScheduleOptionDrawer = ({
  onSubmit,
  ...props
}: ScheduleOptionDrawerProps) => {
  const { handleSubmit, setValue, watch } = useForm<ScheduleOptionFormValues>({
    resolver: zodResolver(createScheduleOptionFormSchema()),
  });

  const scheduleOption = watch(FIELD_NAME_SCHEDULE_OPTION);

  const successButtonProps: SuccessButtonProps = {
    label: "Confirm",
    onClick: handleSubmit(({ schedule_option }) => {
      if (schedule_option) onSubmit?.(schedule_option);
    }),
    disabled: !scheduleOption,
  };

  const cancelButtonProps = {
    label: "Cancel",
  };

  return (
    <Drawer
      {...props}
      title="Schedule"
      endAlignedFooterActions={true}
      successButtonProps={successButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <ScheduleButtonGroup
        value={scheduleOption}
        name={FIELD_NAME_SCHEDULE_OPTION}
        onValueChange={(value: ScheduleOption) =>
          setValue(FIELD_NAME_SCHEDULE_OPTION, value)
        }
      />
    </Drawer>
  );
};
