export const INTERVIEW_TEAM_MOCK = [
  {
    label: "Interviewer 1",
    value: "1",
    email: "interviewer1@example.com",
    calendarConnected: true,
  },
  {
    label: "Interviewer 2",
    value: "2",
    email: "interviewer2@example.com",
    calendarConnected: false,
  },
];

export const DEFAULT_MESSAGES_MOCK = [
  { title: "Option 1", id: 1, message: "This is default message 1" },
  { title: "Option 2", id: 2, message: "This is default message 2" },
  { title: "Option 3", id: 3, message: "This is default message 3" },
];
