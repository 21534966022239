import { cn } from "~/utils/cn"


export const ScheduleOptionButton = ({icon, title, description, isSelected, onClick}: any) => {
    return (
        <div className={cn("w-full p-2 flex flex-row justify-between items-center	border-2 rounded-lg", isSelected ? "bg-blue-50" : "hover:bg-slate-100")}>
            <div className={cn("flex items-center justify-center h-12 w-12 bg-blue-100 rounded-full", isSelected ? "bg-blue-600" : "")}>
                <i className={cn(icon, `${isSelected ? "text-white" : "text-blue-600"}`)} />
            </div>
            <div className="flex-1 text-left px-4">
                <div className="text-sm font-semibold mb-2">{title}</div>
                <div className="text-xs">{description}</div>
            </div>
        </div>
    )
}