import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  CalendarSettings,
  CandidateApplication,
  CompanyUser,
  CountCandidateApplicationDocument,
  GetCandidateApplicationDocument,
  GetCandidateApplicationFiltersDataDocument,
  GetCandidatesApplicationsByStageDocument,
  GetRequisitionPipelineDocument,
  InterviewScheduleEnum,
  NameEnum,
  useAdvanceCandidateApplicationMutation,
  useGetCandidatesApplicationsByStageQuery,
  useGetInterviewerCalendarsQuery,
  useGetJobListingsPipelineQuery,
  useInviteCandidatesToApplyMutation,
} from "~/bff/graphql/generated/graphql";
import { Drawer } from "~/scalis-components/core/drawer";
import {
  SecondaryButtonProps,
  SuccessButtonProps,
} from "~/scalis-components/core/drawer/drawer.types";
import {
  NotificationKind,
  useNotification,
} from "~/scalis-components/core/notification";
import {
  FIELD_NAME_SCHEDULE_RADIO,
  ScheduleOptionEnum,
} from "~/src/app/company/(dashboard)/(applications)/pipeline/components/advance-candidate-drawer/components/schedule-radio/schedule-radio.constants";
import { cn } from "~/utils/cn";
import { notificationErrorHandler } from "~/utils/error";
import {
  FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE,
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  FIELD_NAME_NOTIFICATION_RADIO,
} from "..";
import { AdvanceCandidateDrawerProvider } from "./advance-candidate-drawer-context";
import {
  FIELD_NAME_CANDIDATE_SELECT,
  FIELD_NAME_HOST_CALENDAR_SELECT,
  FIELD_NAME_INTERVIEW_LENGTH_SELECT,
  FIELD_NAME_RECRUITER_SELECT,
  FIELD_NAME_STAGE_SELECT,
  INVITE_CANDIDATE_RADIO_OPTIONS,
  NOTIFICATION_RADIO_OPTIONS,
} from "./advance-candidate-drawer.constants";
import {
  AdvanceCandidateDrawerProps,
  AdvanceCandidateFormValues,
  createAdvanceCandidateFormSchema,
  createSourcingStageFormSchema,
  SourcingStageFormValues,
} from "./advance-candidate-drawer.types";
import { AdvanceCandidateForm } from "./advance-candidate-form/advance-candidate-form";
import { SourcingStageForm } from "./sourcing-stage-form/sourcing-stage-form";

export const AdvanceCandidateDrawer = ({
  milestoneType,
  requisitionId,
  defaultValues,
  contentClassName,
  ...props
}: AdvanceCandidateDrawerProps) => {
  const sourcingStageForm = useForm<SourcingStageFormValues>({
    resolver: zodResolver(createSourcingStageFormSchema()),
    defaultValues: {
      [FIELD_NAME_NOTIFICATION_RADIO]: INVITE_CANDIDATE_RADIO_OPTIONS[0].value,
      [FIELD_NAME_CUSTOM_MESSAGE]: "",
      [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: false,
      [FIELD_NAME_DEFAULT_MESSAGE]: "",
      [FIELD_NAME_CANDIDATE_SELECT]: [],
      ...defaultValues,
    },
  });

  const advanceCandidateForm = useForm<AdvanceCandidateFormValues>({
    resolver: zodResolver(createAdvanceCandidateFormSchema()),
    defaultValues: {
      [FIELD_NAME_NOTIFICATION_RADIO]: NOTIFICATION_RADIO_OPTIONS[0].value,
      [FIELD_NAME_CUSTOM_MESSAGE]: "",
      [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: false,
      [FIELD_NAME_DEFAULT_MESSAGE]: "",
      [FIELD_NAME_SCHEDULE_RADIO]:
        ScheduleOptionEnum.SendSchedulerLink.toString(),
      [FIELD_NAME_CANDIDATE_SELECT]: [],
      [FIELD_NAME_HOST_CALENDAR_SELECT]: [],
      [FIELD_NAME_STAGE_SELECT]: "",
      [FIELD_NAME_INTERVIEW_LENGTH_SELECT]: "",
      [FIELD_NAME_RECRUITER_SELECT]: [],
      ...defaultValues,
    },
  });

  const [advanceCandidate] = useAdvanceCandidateApplicationMutation();
  const [inviteToApply] = useInviteCandidatesToApplyMutation();
  const { notification } = useNotification();

  const { data: stagesData } = useGetJobListingsPipelineQuery({
    variables: { jobRequisitionId: Number(requisitionId) },
  });

  const stageOptions =
    stagesData?.GetJobListing?.[0]?.JobListingJobPipeline?.[0]?.JobPipeline?.TemplatePipeline?.TemplateMilestones?.flatMap(
      milestone =>
        milestone?.TemplateStage?.map(stage => ({
          label: stage.name!,
          value: stage.id!,
        })),
    ) || [];

  const applicationReviewStageId =
    stagesData?.GetJobListing?.[0]?.JobListingJobPipeline?.[0]?.JobPipeline?.TemplatePipeline?.TemplateMilestones?.find(
      milestone => milestone?.name === NameEnum.ApplicationReview,
    )?.TemplateStage?.[0]?.id;

  const { data: sourcingCandidatesData } =
    useGetCandidatesApplicationsByStageQuery({
      variables: {
        requisitionId: Number(requisitionId),
        milestoneIn: [NameEnum.Sourcing],
      },
    });

  const sourcingCandidates =
    (sourcingCandidatesData?.GetTemplateStage?.flatMap(
      stage => stage?.CandidateApplicationByStage,
    ) as CandidateApplication[]) || [];

  const { data: activeCandidatesData } =
    useGetCandidatesApplicationsByStageQuery({
      variables: {
        requisitionId: Number(requisitionId),
        milestoneNotIn: [NameEnum.Sourcing],
      },
    });

  const activeCandidates =
    (activeCandidatesData?.GetTemplateStage?.flatMap(
      stage => stage?.CandidateApplicationByStage,
    ) as CandidateApplication[]) || [];

  const { data: interviewersData } = useGetInterviewerCalendarsQuery();

  const renderChildren = () => {
    if (milestoneType === NameEnum.Sourcing) {
      return (
        <SourcingStageForm
          form={sourcingStageForm}
          candidatesData={sourcingCandidates}
        />
      );
    }

    return (
      <AdvanceCandidateForm
        form={advanceCandidateForm}
        stageOptions={stageOptions as any}
        candidatesData={activeCandidates}
        interviewers={
          (interviewersData?.GetCompanyUsers as CompanyUser[]) || []
        }
        hostCalendarSettings={
          (interviewersData?.GetCalendarSettings as CalendarSettings) ||
          undefined
        }
      />
    );
  };

  const handleSubmit = () => {
    if (milestoneType === NameEnum.Sourcing) {
      return sourcingStageForm.handleSubmit(async values => {
        const filteredCandidatesData = sourcingCandidates.filter(candidate =>
          values.candidate?.includes(candidate.id as string),
        );

        const groupedByJobListingId = filteredCandidatesData.reduce(
          (acc, candidate) => {
            const jobListingId = candidate.jobListingId!;
            if (!acc[jobListingId]) {
              acc[jobListingId] = [];
            }
            acc[jobListingId].push(candidate);
            return acc;
          },
          {} as Record<string, CandidateApplication[]>,
        );
        Object.entries(groupedByJobListingId).forEach(
          async ([jobListingId, candidates]) => {
            try {
              const response = await inviteToApply({
                variables: {
                  input: {
                    candidateProfileIds:
                      candidates.map(candidate =>
                        Number(candidate.candidateProfileId),
                      ) || [],
                    jobListingId: Number(jobListingId),
                    customMessage: values.custom_message || undefined,
                    notifyUploadedCandidates: false,
                    templateMessageId: values.default_message
                      ? Number(values.default_message)
                      : undefined,
                  },
                },
                refetchQueries: [
                  GetCandidatesApplicationsByStageDocument,
                  GetCandidateApplicationFiltersDataDocument,
                  CountCandidateApplicationDocument,
                  GetCandidateApplicationDocument,
                  GetRequisitionPipelineDocument,
                ],
              });
              notification({
                title: "Success",
                description: `Candidates were successfully advanced to the next stage.`,
                kind: NotificationKind.success,
              });
            } catch (error) {
              notificationErrorHandler({ notification });
            }
          },
        );
      })();
    } else {
      return advanceCandidateForm.handleSubmit(async values => {
        const interviewsParams =
          values.should_notify_candidate == "1"
            ? {
                interviewers: values.recruiter?.map(recruiter =>
                  Number(recruiter),
                ),
                interviewSchedule:
                  values.interview_schedule ==
                  ScheduleOptionEnum.SendSchedulerLink.toString()
                    ? InterviewScheduleEnum.Scheduler
                    : InterviewScheduleEnum.Later,
                message: values.custom_message,
                interviewLength: Number(values.interview_length),
                templateMessageId: values.cb_default_message
                  ? Number(values.default_message)
                  : undefined,
                hostCalendarId: values.host_calendar?.[0]
                  ? Number(values.host_calendar?.[0])
                  : undefined,
              }
            : { doNotNotifyCandidate: true };

        try {
          await advanceCandidate({
            variables: {
              input: {
                candidateApplicationIds:
                  values.candidate?.map(candidate => Number(candidate)) || [],
                nextTemplateStageId: Number(values.stageId),
                ...interviewsParams,
              },
            },
            refetchQueries: [
              GetCandidatesApplicationsByStageDocument,
              GetCandidateApplicationFiltersDataDocument,
              CountCandidateApplicationDocument,
              GetCandidateApplicationDocument,
              GetRequisitionPipelineDocument,
            ],
          });
          notification({
            title: "Success",
            description: `Candidates were successfully advanced to the next stage.`,
            kind: NotificationKind.success,
          });
        } catch (error) {
          notificationErrorHandler({ notification });
        }
      })();
    }
  };

  const successButtonProps: SuccessButtonProps = {
    label: "Confirm",
    onClick: handleSubmit,
  };

  const cancelButtonProps: SecondaryButtonProps = {
    label: "Cancel",
  };

  return (
    <AdvanceCandidateDrawerProvider>
      <Drawer
        {...props}
        contentClassName={cn(contentClassName, "p-4")}
        title="Advance Candidate"
        endAlignedFooterActions
        successButtonProps={successButtonProps}
        cancelButtonProps={cancelButtonProps}
      >
        {renderChildren()}
      </Drawer>
    </AdvanceCandidateDrawerProvider>
  );
};
