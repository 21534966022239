"use client";

import React, { PropsWithChildren, useState } from "react";
import {
  ScheduleOptionDrawer,
  ScheduleOption,
} from "../schedule-option-drawer";
import { ScheduleInterviewDrawer } from "~/src/app/company/(dashboard)/pipeline/components/schedule-interview-drawer";
import { SchedulerLinkDrawer } from "../scheduler-link-drawer";
import { AdvanceCandidateDrawer } from "../advance-candidate-drawer";
import { RejectCandidateDrawer } from "../reject-candidate-drawer";
import { RejectCandidateConfirmationDialog } from "../reject-candidate-confirmation-dialog";
import { DiscardCandidateDrawer } from "../discard-candidate-drawer";
import { MatchCandidateDrawer } from "../match-candidate-drawer";
import { DrawerRenderer } from "./components";
import {
  ApplicationActionsDrawersContextProps,
  DrawerId,
  DrawerProps,
  ScreenDrawerProps,
} from "./application-actions-drawers-context.types";

export const ApplicationActionsDrawersContext =
  React.createContext<ApplicationActionsDrawersContextProps>({
    showDrawer: () => {},
  });

export const ApplicationActionsDrawersProvider = ({
  children,
}: PropsWithChildren) => {
  const [onScreenDrawers, setOnScreenDrawers] = useState<
    Map<DrawerId, ScreenDrawerProps | null>
  >(new Map());

  const getDrawer = <T extends DrawerId>(drawerId: T) => {
    const drawer = onScreenDrawers.get(drawerId);
    if (!drawer) return null;
    return drawer as Extract<ScreenDrawerProps, { id: T }>;
  };

  const handleOpenChange = (drawerType: DrawerId) => (isOpen: boolean) => {
    setOnScreenDrawers(prev => {
      const newMap = new Map(prev);
      const drawer = newMap.get(drawerType);
      if (drawer) {
        newMap.set(drawerType, { ...drawer, isOpen });
      }
      return newMap;
    });
  };

  const handleAnimationEnd = (drawerType: DrawerId) => (open: boolean) => {
    if (!open) {
      setOnScreenDrawers(prev => {
        const newMap = new Map(prev);
        newMap.delete(drawerType);
        return newMap;
      });
    }
  };

  const getDrawerStateProps = (drawerType: DrawerId) => {
    return {
      isOpen: !!onScreenDrawers.get(drawerType)?.isOpen,
      onOpenChange: handleOpenChange(drawerType),
      onAnimationEnd: handleAnimationEnd(drawerType),
    };
  };

  const showDrawer = (drawer: DrawerProps | null) => {
    setOnScreenDrawers(prev => {
      const newMap = new Map(prev);

      if (drawer) {
        // Open new drawer
        newMap.set(drawer.id, { ...drawer, isOpen: true });
      } else {
        // Close all drawers
        newMap.forEach((value, key) => {
          if (value) {
            newMap.set(key, { ...value, isOpen: false });
          }
        });
      }

      return newMap;
    });
  };

  const contextValue = React.useMemo(
    () => ({
      showDrawer,
    }),
    [showDrawer],
  );

  return (
    <ApplicationActionsDrawersContext.Provider value={contextValue}>
      {children}

      <DrawerRenderer drawer={getDrawer("scheduleOption")}>
        {drawer => (
          <ScheduleOptionDrawer
            {...getDrawerStateProps("scheduleOption")}
            onSubmit={(scheduleOption: ScheduleOption) => {
              handleOpenChange("scheduleOption")(false);

              if (scheduleOption === ScheduleOption.CALENDAR) {
                showDrawer({
                  id: "scheduleInterview",
                  props: {
                    data: drawer.props.data,
                  },
                });
              } else {
                showDrawer({
                  id: "schedulerLink",
                  props: {
                    requisitionId: drawer.props.requisitionId,
                    defaultValues: {
                      candidates: drawer.props.defaultValues?.candidates,
                    },
                  },
                });
              }
            }}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("scheduleInterview")}>
        {drawer => (
          <ScheduleInterviewDrawer
            {...getDrawerStateProps("scheduleInterview")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("schedulerLink")}>
        {drawer => (
          <SchedulerLinkDrawer
            {...getDrawerStateProps("schedulerLink")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("advanceCandidate")}>
        {drawer => (
          <AdvanceCandidateDrawer
            {...getDrawerStateProps("advanceCandidate")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("rejectCandidate")}>
        {drawer => (
          <RejectCandidateDrawer
            {...getDrawerStateProps("rejectCandidate")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("rejectCandidateConfirmation")}>
        {drawer => (
          <RejectCandidateConfirmationDialog
            {...getDrawerStateProps("rejectCandidateConfirmation")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("discardCandidate")}>
        {drawer => (
          <DiscardCandidateDrawer
            {...getDrawerStateProps("discardCandidate")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>

      <DrawerRenderer drawer={getDrawer("matchCandidate")}>
        {drawer => (
          <MatchCandidateDrawer
            {...getDrawerStateProps("matchCandidate")}
            {...drawer.props}
          />
        )}
      </DrawerRenderer>
    </ApplicationActionsDrawersContext.Provider>
  );
};

export const useApplicationActionsDrawersContext = () => {
  const context = React.useContext(ApplicationActionsDrawersContext);

  if (!context) {
    throw new Error(
      "useApplicationActionsDrawers must be used within a ApplicationActionsDrawersProvider",
    );
  }

  return context;
};
