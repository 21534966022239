import {
  CandidateApplication,
  GetCandidatesApplicationsByStageQuery,
} from "~/bff/graphql/generated/graphql";

export const getCandidateOptions = (
  candidatesData?: Array<CandidateApplication>,
) => {
  if (!candidatesData) return [];

  return candidatesData.map(candidate => {
    const candidateProfile = candidate.CandidateProfile;
    const scalisUser = candidateProfile?.ScalisUser;
    const uploadedCandidate = candidateProfile?.UploadedCandidate;

    const label = scalisUser
      ? [scalisUser?.firstName, scalisUser?.lastName].join(" ")
      : [uploadedCandidate?.firstName, uploadedCandidate?.lastName].join(" ");

    const email = scalisUser
      ? scalisUser?.EmailVerification?.find(
          emailVerification => emailVerification.isPrimary,
        )?.emailAddress
      : uploadedCandidate?.emailAddress;

    return {
      value: candidate.id,
      label,
      email: email ? String(email) : undefined,
    };
  });
};

export const getCandidatesApplications = (
  candidatesData?: GetCandidatesApplicationsByStageQuery,
) => {
  return candidatesData?.GetTemplateStage?.flatMap(
    stage => stage?.CandidateApplicationByStage,
  ) as CandidateApplication[];
};
