import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  ApplicationTypeEnum,
  CandidateApplication,
  MessageOwnerEnum,
  MessageTypeEnum,
  useListTemplateMessagesQuery,
} from "~/bff/graphql/generated/graphql";
import { Alert } from "~/scalis-components/core";
import {
  Body,
  BodySize,
  BodyType,
  Title,
  TitleSize,
} from "~/scalis-components/core/typography";
import { UserSelect } from "~/scalis-components/core/user-select/user-select";
import {
  EmployerMessage,
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  FIELD_NAME_NOTIFICATION_RADIO,
  NotificationRadio,
} from "~/scalis-components/pipeline/candidate-drawer";
import { getCandidateOptions } from "../../pipeline.utils";
import { useAdvanceCandidateDrawerContext } from "../advance-candidate-drawer-context";
import {
  FIELD_NAME_CANDIDATE_SELECT,
  INVITE_CANDIDATE_RADIO_OPTIONS,
  LABEL_NOTIFICATION_INVITATION_RADIO,
} from "../advance-candidate-drawer.constants";
import { SourcingStageFormValues } from "../advance-candidate-drawer.types";

interface SourcingStageFormProps {
  form: UseFormReturn<SourcingStageFormValues>;

  candidatesData: Array<CandidateApplication>;
}

export const SourcingStageForm = (props: SourcingStageFormProps) => {
  const { form, candidatesData } = props;
  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = form;
  const { isNotifyCandidate, setIsNotifyCandidate } =
    useAdvanceCandidateDrawerContext();

  const candidateOptions = getCandidateOptions(candidatesData);

  const candidates = watch(FIELD_NAME_CANDIDATE_SELECT);

  const selectedCandidates = candidateOptions.filter(option =>
    candidates?.includes(option.value as string),
  );

  const selectedCandidateIds = selectedCandidates.map(
    candidate => candidate.value,
  );
  const filteredCandidatesData = candidatesData.filter(candidate =>
    selectedCandidateIds.includes(candidate.id),
  );

  // TODO: Implement the logic to check if the invitation radio should be shown
  // if all chosen candidates are dynamic (i.e. have scalis profile), then they are automatically invited (don't show the radio)
  // if all the chosen candidates are uploaded, then there is a choice of whether to invite or not (show the radio)
  /* TODO: get these from the right source */
  const isAllCandidatesDynamic = filteredCandidatesData.every(
    candidate => candidate.applicationType === ApplicationTypeEnum.Dynamic,
  );
  const isAllCandidatesUploaded = filteredCandidatesData.every(
    candidate => candidate.CandidateProfile?.UploadedCandidate,
  );

  const { data: templateMessagesData } = useListTemplateMessagesQuery({
    variables: {
      messageOwner: MessageOwnerEnum.Company,
    },
  });

  const templateMessages =
    templateMessagesData?.ListTemplateMessages?.filter(
      message =>
        message.messageOwner === MessageOwnerEnum.Company &&
        message.messageType === MessageTypeEnum.InviteToApply,
    ) || [];

  useEffect(() => {
    if (!isAllCandidatesUploaded) {
      setIsNotifyCandidate?.(true);
    }
  }, [isAllCandidatesUploaded]);

  return (
    <div className="flex h-full flex-col justify-between gap-6 overflow-auto">
      <div className="flex flex-col gap-6">
        <UserSelect
          name={FIELD_NAME_CANDIDATE_SELECT}
          label="Candidate(s) being advanced"
          emptyTerm="candidate(s)"
          options={candidateOptions as any}
          value={watch(FIELD_NAME_CANDIDATE_SELECT) ?? []}
          onChange={value => {
            setValue(FIELD_NAME_CANDIDATE_SELECT, value);
          }}
        />

        {isAllCandidatesUploaded ? (
          <NotificationRadio
            label={LABEL_NOTIFICATION_INVITATION_RADIO}
            onValueChange={(name: string, value: string) => {
              setValue(name as keyof SourcingStageFormValues, value);
              setIsNotifyCandidate?.(!!parseInt(value));
            }}
            value={watch(FIELD_NAME_NOTIFICATION_RADIO)}
            notificationOptions={INVITE_CANDIDATE_RADIO_OPTIONS}
          />
        ) : (
          <div className="flex flex-col gap-1">
            <Title size={TitleSize.extraSmall}>Invite to Apply</Title>
            <Body size={BodySize.small} type={BodyType.muted}>
              Advancing this candidate to the Application Review stage means you
              will be inviting them to formally submit an application for this
              job. The candidate may or may not accept this invitation.
            </Body>
          </div>
        )}

        {isNotifyCandidate && (
          <EmployerMessage
            // TODO: add the default messages once they are pulled from backend
            defaultMessages={templateMessages}
            onValueChange={(name: string, value: string) => {
              setValue(name as keyof SourcingStageFormValues, value);
            }}
            triggerValidation={trigger}
            customMessage={watch(FIELD_NAME_CUSTOM_MESSAGE)}
            defaultMessage={watch(FIELD_NAME_DEFAULT_MESSAGE)}
            defaultMessageError={errors[FIELD_NAME_DEFAULT_MESSAGE]?.message}
            customMessageError={errors[FIELD_NAME_CUSTOM_MESSAGE]?.message}
          />
        )}
      </div>

      <div>
        {!isNotifyCandidate && (
          <Alert
            title="Important!"
            message="Candidates will NOT receive notice of this move unless they are invited to create a SCALIS profile and apply to the job."
          />
        )}
      </div>
    </div>
  );
};
