import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Title, TitleSize } from "~/scalis-components/core/typography";
import { Alert } from "~/scalis-components/core";
import { RatingField } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/reject-candidates/reject-candidate-drawer/components/step-rating-candidates/components/rating-field";
import { QUESTIONS_LIST } from "./candidate-rating-form.constants";
import { RejectCandidateFormValues } from "../reject-candidate-drawer.types";

interface CandidateRatingFormProps {
  form: UseFormReturn<RejectCandidateFormValues>;
}

export const CandidateRatingForm = ({ form }: CandidateRatingFormProps) => {
  const { setValue } = form;

  const { fields } = useFieldArray({
    control: form.control,
    name: "questions",
  });

  return (
    <div className="flex h-full flex-col justify-between gap-6 overflow-auto">
      <div className="flex flex-col gap-4">
        <Title size={TitleSize.extraSmall}>Rating</Title>

        {fields.map((field, index) => (
          <RatingField
            key={field.id}
            label={QUESTIONS_LIST[index]}
            name={`questions.${index}.value`}
            setValues={(_, value) => {
              setValue(`questions.${index}.score`, value);
            }}
          />
        ))}
      </div>

      <Alert
        title="Important!"
        message="Rejecting candidates cannot be undone."
      />
    </div>
  );
};
