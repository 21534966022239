import React, { ReactNode, useState } from "react";
import { cn } from "~/utils/cn";
import { ScrollArea } from "~/scalis-components/core/scroll-area";
import { Divider } from "~/scalis-components/core/divider";
import { Option } from "~/scalis-components/core/select/select.types";
import { EmptyList } from "../../../select/components/drop-list/components/empty-list";

export interface PopoverProps<T extends Option> {
  options: T[];
  filter: (value: string, option: T) => boolean;
  renderItem: (option: T) => ReactNode;
}

export const Popover = <T extends Option>({
  options,
  filter,
  renderItem,
}: PopoverProps<T>) => {
  const [search, setSearch] = useState("");
  const filteredOptions = options.filter(option => filter(search, option));

  return (
    <>
      <div
        className={
          "flex h-12 items-center gap-2 px-3 py-2 text-neutral-primary"
        }
      >
        <i className="fa-regular fa-magnifying-glass" />

        <input
          className={cn(
            "flex h-10 w-full bg-transparent py-3",
            "text-sm leading-6 placeholder:text-neutral-tertiary",
            "outline-none disabled:cursor-not-allowed disabled:opacity-50",
          )}
          onChange={event => setSearch(event.target.value)}
          placeholder="Search"
        />
      </div>
      <Divider />
      {filteredOptions.length === 0 ? (
        <EmptyList />
      ) : (
        <ScrollArea className={`max-h-56 overflow-auto p-2`}>
          {filteredOptions.map(renderItem)}
        </ScrollArea>
      )}
    </>
  );
};
