import { z } from "zod";
import { SourcingOrInviteOption } from "./match-candidate-drawer.types";

export const matchCandidateFormSchema = z.object({
  candidateProfileIds: z.array(z.string()),
  customMessage: z.string().optional(),
  templateMessageId: z.string().optional(),
  isDefaultMessage: z.boolean().optional(),
  requisitionId: z.string(),
  jobListingId: z.string(),
  sourcingOrInvite: z.nativeEnum(SourcingOrInviteOption),
});

export const MATCH_CANDIDATES_RADIO_OPTIONS = [
  {
    label: "Send to Sourcing Stage (candidate will not be notified)",
    value: SourcingOrInviteOption.Sourcing,
  },
  {
    label: "Invite to Apply (candidate will be notified of request)",
    value: SourcingOrInviteOption.Invite,
  },
];
